import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../redux/store";
import Grid from "@material-ui/core/Grid";

import LoadingSpinner from "../../../../common/elements/Loader";
import ProductDescription from "../../../shared/ProductDescription";
import {
  ProductCategorySmalls,
  ProductCategory,
  ParcelType,
} from "../../../../enums/productEnum";

import {
  getAuctionProductsQuery,
  isSubItemsAvailable,
  getModifiedProductDetailsResponse,
} from "../../../../utils/preview/DetailsPageUtils";
import { setProductPreviewListName } from "../../../../redux/slice/product/productSlice";
import {
  StyledActionContainer,
  StyledArrowWrapper,
  StyledBackBtn,
  StyledBackBtnContainer,
  StyledBidContainer,
  StyledBrandName,
  StyledContentWrapper,
  StyledGridWrapper,
  StyledLabel,
  StyledLayoutGrid,
  StyledProductCarousalColumn,
  StyledTitleContainer,
  StyledWrapper,
  StyledWrapperID,
} from "../../../../pages/preview/PreviewProductDetailsPageStyles";
import ItemDescription from "../../../../pages/preview/components/details/ItemDescription";
import NavigationControls from "../../../../pages/preview/components/details/NavigationControls";
import ProductDetails from "../../../../pages/preview/components/details/ProductDetails";
import ShareButton from "../../../../pages/preview/components/details/ShareButton";
import SubNavigationControls from "../../../../pages/preview/components/details/SubNavigationControls";
import WatchButton from "../../../../pages/preview/components/details/WatchButton";
import { Box } from "@material-ui/core";
import { getNumberFormat } from "../../../../services/NumberFormatService";
import styled from "styled-components";
import DetailsNotes from "../../details/DetailsNotes";
import Alert from "../../../../common/elements/Alert";
import copy from "copy-to-clipboard";
import ProductCarouselNew from "./ProductCarouselNew";
import { StyledInquiryContainer } from "../../details/inquiry/InquiryDetailsCss";
import BasicPopover from "../../preview/exratemarquee/BasicPopover";
import ProductDetailsInquiry from "../../details/inquiry/ProductDetailsInquiry";
import { StyledModal } from "../../../../common/materials/GenericStyleComponent";
import NewPreBiddingForm from "../../../../pages/preview/components/details/NewPreBiddingForm";
import { getAuctionProducts, getProductDetails } from "../DetailsPageService";
import ApiTimeout from "../../../../modules/ApiTimeout";
import { getRapnetPercentage } from "../../../../common/GenericFunctions";
import { StyledGrid } from "../../../../pages/preview/components/details/PreBiddingFormStyles";
import {
  StyledNotAvailableImage,
  StyledProductImage,
} from "../../../../pages/preview/components/details/ProductCarouselStyles";
import { StyledNoImgGrid } from "../../details/DetailsCarousel";

const StyledPrebidingContainer = styled(Box)`
  color: #2660ad;
  border: 4px solid #2660ad;
  background: #bcd2e8;
  padding: 10px;
  margin: 10px;
`;
const StyledName = styled(Grid)`
  font-size: 1em;
  font-weight: bold;
  color: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  text-align: right;
  align-items: center;
  display: flex;
  justify-content: end;
`;
interface IProps {
  redirectFromPage: string;
}

let isReadOnlyUser: any;
let redirectfromAdmin: any;

let isAuctionEnd: any;
const NewDiamondDetailsContainer = (props: IProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch: AppDispatch = useDispatch();
  // @ts-ignore
  const { category, auctionId, productId, auctionDate } = useParams();

  const PRODUCT_LIST = "auctionProductsList";

  const [showPageLoader, setShowPageLoader] = useState<boolean>(true);
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [auctionProducts, setAuctionProducts] = useState<any[]>([]);
  const [openInquiy, setOpenInquiry] = useState(false);
  const [productDetails, setProductDetails] = useState<any>([]);
  // 'productIndex' is used to keep the current index of product in 'auctionProducts' list.
  // basically we are traversing on 'auctionProducts' list to get details of current selected product.
  // 'productIndex' is used for navigationControls. To traverse prev/next product.
  const [openToast, setOpenToast] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [showAlertMessage, setShowAlertMessage] = useState("");
  const [userBlock, setUserBlock] = useState(false);
  const [productIndex, setProductIndex] = useState<number>(0);
  const [apiTimeout, setApiTimeout] = useState(false);
  // 'items' keep the productDetails with subItem medias into media array. see: 'getModifiedResponseObj' function
  const [items, setItems] = useState<any>();
  const [count, setCount] = useState<number>(0);
  let userCurrencyCode = localStorage.getItem("preferredCurrency");
  let userCurrencySymbol = localStorage.getItem("preferredCurrencySymbol");

  useEffect(() => {
    isReadOnlyUser = localStorage.getItem("isReadOnlyUser");
    isReadOnlyUser = JSON.parse(isReadOnlyUser);
    const urlParams = new URLSearchParams(window.location.search);
    redirectfromAdmin = urlParams.get("redirectFromPreview");
    isAuctionEnd = urlParams.get("isAuctionend");
    setShowPageLoader(true);
    // TODO: Apply previous page selected filters if any.

    // API calls
    fetchProductDetails(productId);
    getAuctionProductsList();
  }, []);

  useEffect(() => {
    if (auctionProducts !== undefined && auctionProducts !== null) {
      setAuctionProducts(auctionProducts);
      getProductById(auctionProducts);
      setShowLoader(false);
    }
  }, [auctionProducts]);

  useEffect(() => {
    if (isSubItemsAvailable(productDetails)) {
      setItems(getModifiedProductDetailsResponse(productDetails));
      setCount(0);
    } else {
      setItems(productDetails);
      setCount(0);
    }
  }, [productDetails]);

  const getAuctionProductsList = async () => {
    dispatch(setProductPreviewListName({ listName: PRODUCT_LIST }));
    let { response, status } = await getAuctionProducts({
      auctionId,
      query: getAuctionProductsQuery(auctionId),
      category: ProductCategory.DIAMONDS,
    });
    if (status === 200) {
      if (response.results !== undefined) {
        if (response.results.length === 0) {
          if (
            isAuctionEnd === null ||
            isAuctionEnd === undefined ||
            isAuctionEnd === ""
          ) {
            setAuctionProducts([]);
            history.push("/preview");
          }
        } else {
          setAuctionProducts(response.results);
        }
      }
    } else if (status === 403) {
      setUserBlock(true);
    } else if (status === 505) {
      setShowLoader(false);
      setApiTimeout(true);
    } else {
      setOpenToast(false);
      setShowAlertMessage(response);
      setAlertType("error");
      setShowLoader(false);
    }
  };

  const getProductById = (productList: any) => {
    if (productList !== null || productList !== null) {
      productList.map((product: any, indx: number) => {
        if (product.perId === productId) {
          let index: number = productList.findIndex((x: any) => x.perId === productId);
          setProductIndex(index); // Setting index for product navigation controls.
          fetchProductDetails(product.perId);
        }
      });
    }
  };

  const fetchProductDetails = async (id: string) => {
    let { response, status } = await getProductDetails({
      productId: id,
      category: ProductCategorySmalls.DIAMONDS,
    });
    if (status === 200) {
      setProductDetails(response);
      setShowLoader(false);
      setShowPageLoader(false);
    } else if (status === 500) {
      setShowLoader(false);
      setApiTimeout(true);
      if (response === null || response === undefined || response === "") {
        setApiTimeout(true);
      }
    } else if (status === 403) {
      setUserBlock(true);
    } else if (status === 505) {
      setShowLoader(false);
      setApiTimeout(true);
    } else {
      setOpenToast(false);
      setShowAlertMessage(response);
      setAlertType("error");
      setShowLoader(false);
    }
  };

  const handleBackBtn = () => {
    // TODO: Handle all filters, page number, pagination,start and end count, optionValue
    // while going back to product list page.
    if (items && items.serialNo) {
      localStorage.setItem("currentPDItem", JSON.stringify(items.serialNo));
    }
    //set value in tabSelectedVal localStorage key on parcel

    if (items && items.parcelType === "Diamond") {
      localStorage.setItem("tabSelectedVal", "1");
    } else {
      localStorage.setItem("tabSelectedVal", "2");
    }
    let filteredValue = auctionProducts.filter(
      (value: any) => value.serialNo === items.serialNo
    );
    let index = auctionProducts.indexOf(filteredValue[0]);
    // login to check if user has increased items per page
    if (localStorage.getItem("itemsPerPage")) {
      let savedCount: any = localStorage.getItem("itemsPerPage");
      let endCount: number = parseInt(savedCount);
      let sum = (index + 1) / endCount;
      let page = Math.ceil(sum);
      localStorage.setItem("currentPdPage", JSON.stringify(page));
    } else {
      let page = Math.ceil((index + 1) / 20);
      localStorage.setItem("currentPdPage", JSON.stringify(page));
    }

    let val = localStorage.getItem("optionValue");
    if (val !== "" && val !== "null" && val != null) {
      let page = 1;
      localStorage.setItem("currentPdPage", JSON.stringify(page));
    }
    // this.props.history.push(`/preview`);
    if (redirectfromAdmin === "true") {
      history.push({
        pathname: `/preview/products/${auctionId}/${items.auctionName}/${auctionDate}?source=admin`,
      });
    } else {
      history.push({
        pathname: `/preview/products/${auctionId}/${items.auctionName}/${auctionDate}`,
      });
    }
  };

  const onClickSignIn = () => {
    history.replace("/");
  };

  const subItemPreviousBtnHandler = () => {
    if (count === 1) {
      setItems(productDetails);
      setCount(count - 1);
    } else if (productDetails.subItems.length === count) {
      setItems(productDetails.subItems[count - 2]);
      setCount(count - 1);
    } else {
      if (count > 0) {
        setItems(productDetails.subItems[count - 2]);
        setCount(count - 1);
      }
    }
  };

  const subItemNextBtnHandler = () => {
    if (productDetails.subItems.length > count) {
      setItems(productDetails.subItems[count]);
      setCount(count + 1);
    }
  };

  const renderBackBtn = () => {
    return (
      <StyledBackBtnContainer item={true} xs={12}>
        <StyledBackBtn
          onClick={handleBackBtn}
          src="/images/controls/left_arrow_round_edges.png"
        />
        <span className="spanText" onClick={handleBackBtn}>
          {t("common:preview:showAllProduct")}
        </span>
      </StyledBackBtnContainer>
    );
  };

  const shareBtnHandler = () => {
    let languageCode =
      localStorage.getItem("language") === "EN"
        ? "en"
        : localStorage.getItem("language") === "zh_CN"
          ? "zh_CN"
          : "jp";
    copy(
      `${window.location.hostname}/${languageCode}/product/${productId}/${auctionDate}/detail`
    );
    setAlertType("success");
    setOpenToast(true);
    setShowAlertMessage(`${t("common:preview:linkCopied")}`);
    setTimeout(() => {
      setOpenToast(false);
    }, 3000);
  };

  const getMediasForCarosuel = () => {
    const medias = [...items.medias];
    for (let i = 0; i < medias.length; i++) {
      let mediaItem = medias[i];
      if (mediaItem.mediaType === "PHOTO") {
        if (mediaItem.primary === true) {
          medias.splice(i, 1);
          medias.unshift(mediaItem);
          break;
        }
      }
    }
    return medias;
  };

  const renderActionControls = () => {
    return (
      <StyledActionContainer
        item={true}
        xs={12}
        className="StyledActionContainer"
      >
        <StyledWrapper container={true} spacing={0}>
          <Grid item xs={8} sm={6} md={8} lg={8}>
            <Grid container={true} spacing={0}>
              <Grid item={true} xs={6} className="renderShareBtnContainer">
                {items !== undefined && count === 0 && (
                  <ShareButton
                    productId={productId}
                    auctionDate={auctionDate}
                    category={category}
                    shareBtnHandler={shareBtnHandler}
                  />
                )}
              </Grid>
              <Grid item={true} xs={6} className="renderWatchBtnContainer">
                {items !== undefined && count === 0 && (
                  <WatchButton
                    productData={productDetails}
                    getDetails={fetchProductDetails}
                    alertType={alertTypeRender}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
          <StyledArrowWrapper
            item
            xs={4}
            sm={6}
            md={4}
            lg={4}
            className="StyledArrowWrapper"
          >
            <NavigationControls
              initialProductIndex={productIndex}
              auctionProducts={auctionProducts}
              getDetails={fetchProductDetails}
            />
          </StyledArrowWrapper>
        </StyledWrapper>
      </StyledActionContainer>
    );
  };

  const PreBiddingDetail = (productData: any) => {
    const { currency, preBidPrice, exchangeRates } = productData;
    const rate = exchangeRates && exchangeRates[0]["rate"];

    // Selectors
    const preferredCurrency = localStorage.getItem("preferredCurrency");
    const preferredCurrencySymbol = localStorage.getItem(
      "preferredCurrencySymbol"
    );

    return (
      <>
        {preBidPrice !== undefined && preBidPrice !== null && (
          <StyledLabel>
            {preBidPrice && getNumberFormat(Number(preBidPrice))}{" "}
            {currency && currency["symbol"]}
            {preferredCurrency !== currency["code"] ? (
              <>
                {"("}
                {preBidPrice &&
                  rate &&
                  getNumberFormat(Math.round(Number(preBidPrice) * rate))}
                {`${preferredCurrencySymbol})`}
              </>
            ) : (
              ""
            )}
          </StyledLabel>
        )}
      </>
    );
  };

  const timeout = (timeout: boolean) => {
    if (timeout === true) {
    }
  };

  const oninquiry = () => {
    setOpenInquiry(true);
  };

  const convertedPlaceHolderValue = (
    currencyCodeVal: any,
    price: any,
    exchangeRate: any
  ) => {
    //get user currency code form local storeage
    if (
      currencyCodeVal === undefined ||
      currencyCodeVal === null ||
      price === undefined ||
      price === null ||
      price === 0 ||
      exchangeRate === undefined ||
      exchangeRate === null
    ) {
      return " ";
    } else {
      if (userCurrencyCode !== currencyCodeVal.code) {
        const rate = exchangeRate?.[0]?.["rate"] ?? 1;
        let conversion = new Intl.NumberFormat("us-en").format(
          Math.round(price * rate)
        );
        let conversions = new Intl.NumberFormat("us-en").format(
          Math.round(price)
        );
        return `${conversions}${currencyCodeVal.symbol}  (${conversion} ${userCurrencySymbol})`;
      } else {
        let conversions = new Intl.NumberFormat("us-en").format(
          Math.round(price)
        );
        return `${conversions} ${userCurrencySymbol}`;
      }
    }
  };

  const convertedAmt = (
    currencyCodeVal: any,
    price: any,
    exchangeRate: any,
    carat: any
  ) => {
    //get user currency code form local storeage
    let amt: any = price * carat;
    if (
      currencyCodeVal === undefined ||
      currencyCodeVal === null ||
      amt === undefined ||
      amt === null ||
      amt === 0 ||
      exchangeRate === undefined ||
      exchangeRate === null
    ) {
      return "";
    } else {
      const rate = exchangeRate?.[0]?.["rate"] ?? 1;
      if (userCurrencyCode !== currencyCodeVal.code) {
        let conversion = new Intl.NumberFormat("us-en").format(
          Math.round(amt * rate)
        );
        let conversions = new Intl.NumberFormat("us-en").format(
          Math.round(amt)
        );
        return ` ${conversions} ${currencyCodeVal.symbol}  (${conversion}${userCurrencySymbol})`;
      } else {
        let conversions = new Intl.NumberFormat("us-en").format(
          Math.round(amt)
        );
        return ` ${userCurrencySymbol} (${conversions})`;
      }
    }
  };

  const alertTypeRender = (
    alertType: string,
    openToast: boolean,
    showMessage: string
  ) => {
    setShowAlertMessage(showMessage);
    setOpenToast(openToast);
    setAlertType(alertType);
    setTimeout(() => {
      setOpenToast(false);
    }, 2000);
  };

  const isParcelTypeDiamond = () => {
    return items?.parcelType === ParcelType.DIAMOND;
  };
  const isParcelTypeJewellery = () => {
    return items?.parcelType === ParcelType.JEWELLERY;
  };

  const renderImageNotAvailable = () => {
    return (
      <StyledGrid container={true} spacing={0}>
        <StyledNotAvailableImage>
          <StyledProductImage
            src={`/images/${isParcelTypeDiamond() || isParcelTypeJewellery()
              ? "default-diamond.png"
              : "default-parcel.png"
              }`}
          ></StyledProductImage>
        </StyledNotAvailableImage>
      </StyledGrid>
    );
  };

  return (
    <>
      {showPageLoader ? (
        <LoadingSpinner />
      ) : (
        <>
          {apiTimeout ? (
            <ApiTimeout t={t} {...props} />
          ) : (
            <StyledLayoutGrid
              container={true}
              spacing={0}
              className="diamondProductContainer"
              style={{ marginTop: "10px" }}
            >
              <Grid container={true} spacing={0} className="GridContainer">
                <StyledGridWrapper
                  item={true}
                  xs={12}
                  className="StyledGridWrapper"
                >
                  <Grid
                    container={true}
                    spacing={0}
                    className="actionContainer"
                  >
                    <StyledBackBtnContainer item xs={12} sm={12} md={6} lg={8}>
                      {renderBackBtn()}
                    </StyledBackBtnContainer>
                    <Grid item xs={12} sm={12} md={6} lg={4}>
                      {renderActionControls()}
                    </Grid>
                    <StyledTitleContainer
                      item={true}
                      xs={12}
                      sm={12}
                      md={12}
                      lg={8}
                    >
                      <StyledBrandName>
                        <ProductDescription truncateLength={100} data={items} />
                      </StyledBrandName>
                    </StyledTitleContainer>{" "}
                    <StyledName item={true} xs={12} sm={12} md={12} lg={4}>
                      {items?.auctionName === undefined
                        ? " "
                        : items?.auctionName}
                    </StyledName>
                  </Grid>
                </StyledGridWrapper>

                <Grid item={true} xs={12}>
                  <StyledContentWrapper container={true} spacing={0}>
                    <StyledProductCarousalColumn
                      item={true}
                      xs={12}
                      sm={12}
                      md={6}
                      lg={6}
                      xl={6}
                    >
                      {items.medias !== undefined && items.medias.length > 0 ? (
                        <ProductCarouselNew
                          medias={getMediasForCarosuel()}
                          productData={items}
                        />
                      ) : (
                        <StyledNoImgGrid>{renderImageNotAvailable()}</StyledNoImgGrid>
                      )}
                    </StyledProductCarousalColumn>
                    <Grid item={true} xs={12} sm={12} md={6} lg={6} xl={6}>
                      <StyledBidContainer container={true} spacing={0}>
                        <Grid item={true} xs={12} sm={12}>
                          <Grid container={true} spacing={0}>
                            <Grid item={true} xs={12} xl={10} lg={10}>
                              {items !== undefined && items !== null && (
                                <>
                                  <NewPreBiddingForm
                                    productData={items}
                                    count={count}
                                    getDetails={fetchProductDetails}
                                    PreBiddingDetail={PreBiddingDetail}
                                  />
                                </>
                              )}
                            </Grid>
                            <Grid
                              item={true}
                              xs={12}
                              xl={8}
                              lg={8}
                              md={12}
                              spacing={0}
                            >
                              <StyledPrebidingContainer>
                                <div
                                  style={{
                                    fontSize: "1.2em",
                                    fontWeight: "bold",
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "5px",
                                  }}
                                >
                                  <span>PreBid Price:</span>
                                  <span>{PreBiddingDetail(items)}</span>
                                </div>
                                <div>
                                  {items !== undefined && items !== null && (
                                    <span>
                                      {getRapnetPercentage(
                                        items.rapnetPercentage,
                                        items?.exchangeRates,
                                        items.currency,
                                        items.preBidPrice,
                                        items.rapnetPrice
                                      )}
                                    </span>
                                  )}
                                </div>
                                <div>
                                  <span>Amount:</span>
                                  {items !== undefined && items !== null && (
                                    <span>
                                      {convertedAmt(
                                        items.currency,
                                        items.preBidPrice ?? 0,
                                        items?.exchangeRates,
                                        Number(items.carat)
                                      )}
                                    </span>
                                  )}
                                </div>
                              </StyledPrebidingContainer>
                            </Grid>

                            <Grid item={true} xs={12}>
                              <Box style={{ marginLeft: "10px", gap: "5px" }}>
                                <div
                                  style={{ marginLeft: "10px", gap: "10px" }}
                                >
                                  <span>
                                    {t(
                                      "common:preview:perItemDescription:startingPrice"
                                    )}{" "}
                                  </span>
                                  {items !== undefined && items !== null && (
                                    <span>
                                      {convertedPlaceHolderValue(
                                        items.currency,
                                        items.caratMinimumPrice,
                                        items?.exchangeRates
                                      )}
                                    </span>
                                  )}
                                </div>
                                <div
                                  style={{ marginLeft: "10px", gap: "10px" }}
                                >
                                  {items !== undefined && items !== null && (
                                    <span>
                                      {getRapnetPercentage(
                                        items.rapnetPercentage,
                                        items?.exchangeRates,
                                        items?.currency,
                                        items?.caratMinimumPrice,
                                        items?.rapnetPrice
                                      )}
                                    </span>
                                  )}
                                </div>
                                <div
                                  style={{ marginLeft: "10px", gap: "10px" }}
                                >
                                  <span>Amount: </span>
                                  {items !== undefined && items !== null && (
                                    <span>
                                      {convertedPlaceHolderValue(
                                        items.currency,
                                        items.startingPrice,
                                        items.exchangeRates
                                      )}
                                    </span>
                                  )}
                                </div>
                              </Box>
                            </Grid>
                            <Grid item={true} xs={12} xl={8} lg={8}>
                              {items !== "" && (
                                <DetailsNotes
                                  t={t}
                                  {...props}
                                  {...items}
                                  count={count}
                                  apiTimeout={timeout}
                                  noteSaved={() =>
                                    fetchProductDetails(productId)
                                  }
                                  auctionType={productDetails?.auctionType}
                                  isReadOnlyUser={isReadOnlyUser}
                                />
                              )}
                              {productDetails.note !== "" && (
                                <StyledLabel>{productDetails.note}</StyledLabel>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </StyledBidContainer>
                    </Grid>
                    <Grid item={true} xs={12}>
                      <StyledContentWrapper container={true} spacing={0}>
                        <Grid
                          item={true}
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                        >
                          <Grid item={true} xs={12}>
                            <StyledWrapperID item={true} xs={12}>
                              {items !== undefined && (
                                <ItemDescription productDetails={items} />
                              )}
                              {isSubItemsAvailable(productDetails) && (
                                <SubNavigationControls
                                  isPrevDisabled={count === 0}
                                  isNextDisabled={
                                    productDetails.subItems.length === count
                                  }
                                  onPrevious={subItemPreviousBtnHandler}
                                  onNext={subItemNextBtnHandler}
                                />
                              )}
                            </StyledWrapperID>
                            {items !== undefined && (
                              <ProductDetails
                                isPDView={true}
                                auctionDate={auctionDate}
                                productData={items}
                                onClickSignIn={onClickSignIn}
                              />
                            )}
                            <StyledInquiryContainer>
                              <BasicPopover oninquiryFun={oninquiry} />
                            </StyledInquiryContainer>
                          </Grid>
                        </Grid>
                      </StyledContentWrapper>
                    </Grid>
                    {openToast && (
                      <Alert
                        showAlertMessage={showAlertMessage}
                        alertType={alertType}
                      />
                    )}
                    {openInquiy && (
                      <StyledModal
                        open={openInquiy}
                        onClose={() => {
                          setOpenInquiry(false);
                        }}
                      >
                        <ProductDetailsInquiry
                          t={t}
                          data={items}
                          closeModal={() => {
                            setOpenInquiry(false);
                          }}
                          auctionId={auctionId}
                        />
                      </StyledModal>
                    )}
                  </StyledContentWrapper>
                </Grid>
              </Grid>
            </StyledLayoutGrid>
          )}
        </>
      )}
    </>
  );
};

export default NewDiamondDetailsContainer;
