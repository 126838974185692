import styled, { css } from "styled-components";
import Grid from "@material-ui/core/Grid";
import Modal from "@material-ui/core/Modal";
import { backgroundGradient } from "../../../common/materials/LinearGradient";
import { Box, Typography } from "@mui/material";

const StyledCardContainer = styled(Grid)`
  flex-grow: 1;
  flex-basis: 16%;
  margin: 10px 25px 25px 25px;

  color: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  .centerContent {
    text-align: center;
  }
  .borderRightBlue {
    border-right: 1px solid
      ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  }
  .borderBottomBlue {
    border-bottom: 1px solid
      ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  }
  .secondaryLabel {
    color: ${(props) => props.theme.colors.base.black};
    font-size: 1.6em;
    ${(props) => props.theme.breakpoints.only("xs")} {
      font-size: 1em;
    }
  }
  .notesContainer {
    border: 3px solid darkblue;
    border-style: dashed;
  }
  .posRelative {
    position: relative;
  }
  .posAbsolute {
    position: absolute;
    top: -10px;
    width: 100%;
    background: ${(props) => props.theme.colors.base.white};
  }
  .wishListContainer {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .textWrap {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 5px;
  }
  .display {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .fontSize {
    font-size: 1.4em;
    ${(props) => props.theme.breakpoints.only("xs")} {
      font-size: 1em;
    }
  }
  .labelFontSize {
    font-size: 18px;
    ${(props) => props.theme.breakpoints.only("xs")} {
      font-size: 14px;
    }
  }
  .secondaryLabelFontSize {
    font-size: 18px;
    ${(props) => props.theme.breakpoints.only("xs")} {
      font-size: 14px;
    }
  }
  .notDisablebutton {
    background: rgba(1, 74, 153, 1);
    cursor: pointer;
  }
  .disableButton {
    cursor: default;
    background: ${(props) => props.theme.colors.base.lightGrey};
  }
  .btnMargin {
    margin-top: 10px;
    ${(props) => props.theme.breakpoints.only("xs")} {
      margin-top: 2px;
    }
  }
  .spaceBetween {
    justify-content: space-between;
  }
  .mobileSize {
    width: 6em !important;
    height: 6em !important;
    ${(props) => props.theme.breakpoints.only("xs")} {
      width: 4em !important;
      height: 4em !important;
      box-shadow: inset 0 0 0 5.90909090909091px #d1d1d1 !important;
    }
  }
  @media (min-width: 1850px) and (max-width: 1980px) {
    zoom: 1.50;
  }
`;

const StyledinnerGrid = styled(Grid)`
  ${(props) => props.theme.breakpoints.only("sm")} {
    justify-content: center;
  }
`;

const StyledGrid = styled(Grid)`
  display: flex-wrap;
  overflow: auto;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const StyledWrapper = styled(Grid)`
  text-align: center;
  max-width: 1000px;
  margin: 30px auto;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    margin: 10px auto;
    max-width: -webkit-fill-available;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    max-width: -webkit-fill-available;
    margin: 10px auto;
  }

  ${(props) => props.theme.breakpoints.only("lg")} {
    max-width: 1200px;
  }
  ${(props) => props.theme.breakpoints.only("xl")} {
    max-width: 1000px;
  }
  
  @media (min-width: 1850px) and (max-width: 1980px) {
    max-width: 1600px;
    margin: 50px auto;
  }
  @media screen and (width: 912px) {
    margin: 50px auto;
  }
`;

const StyledDetailTitle = styled(Grid)`
  padding: 5px;
  font-size: 18px;
  background: rgba(1,74,153,1);
  color: white;
`;

interface IChangeColorProps {
  changeColor?: String;
}

const StyledCurrentBid = styled.div<IChangeColorProps>`
  color: ${(props) => props.theme.colors.base.black};
  ${(props) =>
    props.changeColor === "black" &&
    css`
      color: ${(props) => props.theme.colors.base.black};
    `}
  ${(props) =>
    props.changeColor === "green" &&
    css`
      color: ${(props) => props.theme.colors.base.green};
    `}

    ${(props) =>
    props.changeColor === "red" &&
    css`
      color: ${(props) => props.theme.colors.base.red};
    `}
`;

const StyledBiddingPriceContainer = styled(Grid)`
  color: ${(props) => props.theme.colors.base.white};
  font-size: 0.8em;
  padding: 8px;
  align-items: center;
  border: 1px solid ${backgroundGradient};
  text-align: center;
  cursor: pointer;
`;

const StyledDisableButton = styled(Grid)`
  color: ${(props) => props.theme.colors.base.white};
  font-size: 0.8em;
  padding: 10px;
  align-items: center;
  border: 1px solid ${backgroundGradient};
  text-align: center;
  cursor: default;
  background: ${(props) => props.theme.colors.base.lightGrey};
  @media screen and (width: 375px) {
    padding: 8px;
  }
`;

const StyledModal = styled(Modal)`
  display: flex;
  outline: none;
  align-items: center;
  justify-content: center;
`;

const StyledMessage = styled(Grid)`
  color: ${(props) => props.theme.colors.base.red};
  font-size: 0.8em;
`;

const StyledContainer = styled.div`
  width: 100%;
`;

const StyledBreadkPopup = styled(Grid)`
  align-items: center;
  justify-content: center;
  background: white;
  width: 100%;
  z-index: 1000;
  top: 10%;
  height: 100%;
`;
const StyledComponent = styled(Grid)`
  outline: none;
  display: flex;
  justify-content: center;
  .text {
    display: flex;
    text-align: center;
    align-items: center;
    font-size: 2em;
    margin-bottom: 10px;
    font-family: Calibre-Regular;
    color: ${(props) => props.theme.colors.base.grey};
  }
`;

const StyledWrapperPopUp = styled(Grid)`
  background: ${(props) => props.theme.colors.base.white};
  width: 100%;
  text-align: center;

  border-radius: 10px;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    width: 275px;
    padding: 0.5em;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    width: 400px;
  }
  ${(props) => props.theme.breakpoints.between("md", "xl")} {
    width: 700px;
  }
`;
const StyledAuctionEndedWrapper = styled(Grid)`
  text-align: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
`;
const StyledTextWrapper = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  font-size: 0.8em;
  font-family: Calibre-Regular;
  padding: 0.3em;
  justify-content: center;
  margin-top: 0.3em;
  color: ${(props) => props.theme.colors.base.textColor};

  .secondLine {
    font-size: 1em;
    color: ${(props) => props.theme.colors.base.red};
  }

  .firstLine {
    font-size: 1.3em;
    color: ${(props) => props.theme.colors.base.red};
  }
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    font-size: 0.8em;
    margin-top: 14px;
    .secondLine {
      font-size: 0.8em;
    }

    .firstLine {
      font-size: 0.8em;
    }
  }
`;

const StyledTimer = styled.div`
  font-size: 2.5em;
  font-family: "Calibre-Regular";

  .countdownText {
    font-weight: bold;
    width: 100%;
    text-align: center;
  }
  .dateTimeStyle {
    width: 100%;
    display: flex;
    padding: 5px;
    align-items: center;
    justify-content: center;
  }

  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    font-size: 1em;
    margin-top: 0.5em;
  }
`;
const StyledImage = styled.img`
  height: 80%;
  width: 80%;

  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    height: 80%;
    width: 80%;
    margin: 1em 0em;
  }
`;
const StyledPaddleBox = styled(Box)`
  background: url(/images/paddel/paddelSvg.svg);
  height: 110px;
  width: 80px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  ${(props) => props.theme.breakpoints.only("xs")} {
    height: 70px;
    width: 80px;
  }
`;

const StyledTypo = styled(Typography)`
  font-size: 1em;
  color: green;
  padding: 13px;
  margin-left: 8px !important;
  ${(props) => props.theme.breakpoints.only("xs")} {
    padding: 8px;
    margin-left: 5px;
    font-size: 0.8em !important;
  }
`;

const StyledCardHeader = styled.div`
  background: ${backgroundGradient};
  flex-direction: row;
  display: flex;
  height: 30px;
  align-items: center;
  justify-content: space-between;
`;

const StyledWishlistIcon = styled("img")`
  width: 1em;
  height: 1em;
`;

const StyledWatchListContainer = styled.div``;

const StyledGridWrapper = styled(Grid)`
  width: 100%;
  background: ${backgroundGradient};
  padding: 10px;
  display: flex;
  font-size: 10px;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledCurrentProductGridWrapper = styled(Grid)`
  font-size: 12px;
  background: ${backgroundGradient};
`;

const ProductNameContainer = styled(Grid)`
  color: ${(props) => props.theme.colors.base.white};
  font-size: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ProductCountContainer = styled(Grid)`
  color: ${(props) => props.theme.colors.base.white};
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 10px;
`;

const StyledProductImageContainer = styled(Grid)`
  padding: 10px;
  min-height: 220px;
`;

const StyledSeriesNumberContainer = styled(Grid)`
  padding: 5px;
  text-align: right;
`;

const StyledInformationDetailContainer = styled(Grid)`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 5px;
  justify-content: center;
  align-items: center;
  .centerValue {
    display: flex;
    align-items: center;
  }
`;

const StyledNotesContainer = styled(Grid)`
  color: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  font-size: 0.6em;
  float: left;
`;
const StyledStartingPriceContainer = styled(Grid)`
  color: black;
  font-size: 1.6em;
  ${(props) => props.theme.breakpoints.only("xs")} {
    font-size: 1em;
  }
  float: left;
`;

const StyledNotesGridWrapper = styled(Grid)`
  display: flex;
  overflow-y: auto;
  max-height: 85px;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  padding-left: 0.5em;
  padding-right: 0.5em;
  ${(props) => props.theme.breakpoints.down("xs")} {
    padding-top: 0em;
    padding-bottom: 0em;
  }
  ${(props) => props.theme.breakpoints.only("xs")} {
    padding-top: 0em;
    padding-bottom: 0em;
  }
  ${(props) => props.theme.breakpoints.between("lg", "xl")} {
    padding-top: 0.1em;
    padding-bottom: 0.1em;
    padding-left: 0.5em;
    padding-right: 0.5em;
  }
`;

const StyledPriceContainer = styled(Grid)`
  display: flex;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  padding-left: 0.5em;
  padding-right: 0.5em;
  ${(props) => props.theme.breakpoints.down("xs")} {
    padding-top: 0em;
    padding-bottom: 0em;
    flex-direction: column;
  }
  ${(props) => props.theme.breakpoints.only("xs")} {
    padding-top: 0em;
    padding-bottom: 0em;
  }
  ${(props) => props.theme.breakpoints.between("lg", "xl")} {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
  }

  @media (width: 540px) {
    flex-direction: row;
  }
`;

const StyledNoteTextInputDiv = styled.div`
  color: ${(props) => props.theme.colors.base.grey};
  padding: 0em 0.5em;
  font-size: 0.8em;
  ${(props) => props.theme.breakpoints.only("xs")} {
    padding-top: 0em;
    padding-bottom: 0em;
  }
`;
const StyledPriceTextInputDiv = styled.div`
  color: black;
  padding: 0em 0.5em;
  font-size: 1em;
  ${(props) => props.theme.breakpoints.only("xs")} {
    font-size: 0.8em;
  }
`;
interface IChangeColorProps {
  changeColor?: String;
}

const StyledAmountInput = styled.div`
  color: ${(props) => props.theme.colors.base.grey};
  padding: 0em 0.5em;
  font-size: 0.8em;
  float: left;
`;

const StyledPriceGridWrapper = styled(Grid)`
  display: flex;
  overflow-y: auto;
  max-height: 95px;
  padding-top: 0.8em;
  padding-left: 0.5em;
  padding-right: 0.5em;

  ${(props) => props.theme.breakpoints.down("xs")} {
    padding-top: 0em;
    padding-bottom: 0em;
  }
  ${(props) => props.theme.breakpoints.between("lg", "xl")} {
    padding-top: 0.5em;
    padding-bottom: 0.3em;
    padding-left: 0.5em;
    padding-right: 0.5em;
  }
  ${(props) => props.theme.breakpoints.only("xs")} {
    padding-bottom: 0px;
    padding-top: 0px;
  }
`;

const StyledPriceDashContainer = styled(Grid) <{ reserved: any }>`
  position: relative;
  z-index: 1;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: ${(props) => (!props?.reserved ? "url(/images/reserve/reserveBadge1.png)" : "")};
    background-repeat: no-repeat;
    background-position: right top;
    background-size: 6em;
    ${(props) => props.theme.breakpoints.between("xs", "md")} {
      background-size: 5em;
    }
    z-index: 2;
    pointer-events: none;
  }
`;

export {
  StyledCardHeader,
  StyledWishlistIcon,
  StyledWatchListContainer,
  StyledGridWrapper,
  StyledCurrentProductGridWrapper,
  ProductNameContainer,
  ProductCountContainer,
  StyledProductImageContainer,
  StyledSeriesNumberContainer,
  StyledInformationDetailContainer,
  StyledNotesContainer,
  StyledNotesGridWrapper,
  StyledPriceContainer,
  StyledNoteTextInputDiv,
  StyledAmountInput,
  StyledPriceGridWrapper,
  StyledStartingPriceContainer,
  StyledPriceTextInputDiv,
  StyledPaddleBox,
  StyledGrid,
  StyledWrapper,
  StyledDetailTitle,
  StyledCurrentBid,
  StyledBiddingPriceContainer,
  StyledDisableButton,
  StyledModal,
  StyledMessage,
  StyledContainer,
  StyledBreadkPopup,
  StyledComponent,
  StyledWrapperPopUp,
  StyledAuctionEndedWrapper,
  StyledTextWrapper,
  StyledTimer,
  StyledImage,
  StyledCardContainer,
  StyledTypo,
  StyledinnerGrid,
  StyledPriceDashContainer
};
