import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import { GlassMagnifier } from "react-image-magnifiers";

import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import ReactPlayer from "react-player";
import Modal from "@material-ui/core/Modal";
import ViewVideoEnlargeModal from "../../../modules/ViewVideoEnlargeModal";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const StyledArrowWrapper = styled.div`
  position: absolute;
  z-index: 2;
  top: 20%;
  width: 30px;
  cursor: pointer;
  ${(props) => props.theme.breakpoints.between("xs", "md")} {
    top: 40%;
  }
`;

const StyledArrows = styled("img")``;

const StyledWrapperGrid = styled(Grid)`
  background: ${(props) => props.theme.colors.base.white};
  padding: 10px;
`;

export const StyledGrid = styled(Grid)`
  background: ${(props) => props.theme.colors.base.white};
  height: 100%;
  padding: 0em 1em;
  display: bock;
  justify-content: center;
`;

export const StyledNoImgGrid = styled(Grid)`
  background: ${(props) => props.theme.colors.base.white};
  height: 100%;
  padding: 0em 1em;
  display: bock;
  justify-content: center;
  position: relative;
  z-index: 1;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 15px;
    right: 0;
    bottom: 0;
    background-image: url(/images/reserve/reserveBadge2.png);
    background-repeat: no-repeat;
    background-position: left top;
    background-size: 6em;
    z-index: 1;
  }
`;

const StyledCorouselWrapper = styled(Grid)`
  background: ${(props) => props.theme.colors.base.white};
  position: relative;

  ${(props) => props.theme.breakpoints.between("xs", "md")} {
    width: 310px;
    .carousel .slide iframe {
      width: calc(100% - -65px) !important;
    }
  }
  ${(props) => props.theme.breakpoints.between("md", "lg")} {
    width: 450px;
  }
  ${(props) => props.theme.breakpoints.between("lg", "xl")} {
    width: 450px;
  }
  .carousel.carousel-slider {
    overflow: visible;
    position: relative;
  }
  .carousel .slider-wrapper {
    overflow: visible;
  }
  .carousel .slide {
    visibility: hidden;
    background: ${(props) => props.theme.colors.base.white};
  }
  .carousel .slide.selected {
    visibility: visible;
  }
  .fWstHB {
    padding-top: 0px;
  }
`;

export const StyledProductImage = styled("img")`
  width: 100%;
`;

export const StyledLargeText = styled.span`
  color: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  cursor: pointer;
  margin-left: 5px;
  font-weight: bold;
`;

const StyledLargeTxtImg = styled("img")`
  width: 25px !important;
  height: 25px !important;
`;

const StyledLargeTxtCon = styled(Grid)`
  display: flex;
  justify-content: center;
  padding: 10px;
  align-items: center;
`;

export const StyledNotAvailableImage = styled.div`
  justify-content: center;
  position: relative;
  z-index: 1;
  &::before {
    content: "";
    position: absolute;
    top: 10px;
    left: 9px;
    right: 0;
    bottom: 0;
    background-image: url(/images/reserve/reserveBadge2.png);
    background-repeat: no-repeat;
    background-position: left top;
    background-size: 6em;
    z-index: 1;
  }
`;

const StyledFrame = styled.iframe`
  width: 100%;
  height: 340px;
  position: relative;
  ${(props) => props.theme.breakpoints.between("xs", "md")} {
    height: 240px;
  }
  ${(props) => props.theme.breakpoints.between("md", "lg")} {
    height: 340px;
  }
  ${(props) => props.theme.breakpoints.between("lg", "xl")} {
    height: 340px;
  }
`;

const StyledModal = styled(Modal)`
  display: flex;
  outline: none;
  align-items: center;
  justify-content: center;
`;

interface IProps {
  t: any;
  medias: any;
  parcelType: any;
}

var videoUrl: String = "";
var mediaTypeForFullView: String = "";

const DetailsCarousel = (props: IProps) => {
  let { t } = useTranslation();

  const { medias, parcelType } = props;
  const [onLargeVideo, setOnLargeVideo] = useState(false);
  const [show, setShow] = useState<boolean>(true);
  const theme = useTheme();
  const notSmallDevices = useMediaQuery(theme.breakpoints.between("md", "xl"));

  useEffect(() => {
    setShow(false);
    if (medias.length === 1) {
      setTimeout(() => {
        setShow(true);
      }, 50);
    } else {
      setShow(true);
    }
  }, [medias.length]);

  const PlayerSlide = ({ url }: { url: string }) => (
    <ReactPlayer
      width="100%"
      url={url}
      playing={false}
      controls={true}
      muted={true}
      progressInterval={1000}
    />
  );

  const videoLargePopup = (url: any, mediaTypeFullview?: any) => {
    videoUrl = url;
    mediaTypeForFullView = mediaTypeFullview;
    setOnLargeVideo(true);
  };

  const onClose = () => {
    setOnLargeVideo(false);
  };

  return (
    <StyledGrid container={true} spacing={0}>
      {medias !== undefined ? (
        <div>
          {medias !== null && medias.length !== 0 ? (
            <StyledCorouselWrapper>
              {show && (
                <Carousel
                  showStatus={false}
                  showThumbs={false}
                  showIndicators={false}
                  thumbWidth={60}
                  transitionTime={0}
                  swipeable={false}
                  renderArrowPrev={(onClickHandler, hasPrev, label) => (
                    <StyledArrowWrapper
                      onClick={onClickHandler}
                      title={label}
                      style={{ left: 0 }}
                    >
                      {hasPrev ? (
                        <StyledArrows src="/images/controls/left_arrow_round_edges.png" />
                      ) : (
                        <StyledArrows src="/images/controls/left_arrow_round_edges_disabled.png" />
                      )}
                    </StyledArrowWrapper>
                  )}
                  renderArrowNext={(onClickHandler, hasNext, label) => (
                    <StyledArrowWrapper
                      onClick={onClickHandler}
                      title={label}
                      style={{ right: 0 }}
                    >
                      {hasNext ? (
                        <StyledArrows src="/images/controls/right_arrow_round_edges.png" />
                      ) : (
                        <StyledArrows src="/images/controls/right_arrow_round_edges_disabled.png" />
                      )}
                    </StyledArrowWrapper>
                  )}
                >
                  {medias.map((item: any, index: number) => (
                    <StyledWrapperGrid key={index}>
                      {item.mediaType === "PHOTO" ? (
                        <div>
                          {item.thumbnailPresignedUrl !== null ||
                            item.presignedUrl !== null ? (
                            <div>
                              <GlassMagnifier
                                imageSrc={`${item.thumbnailPresignedUrl}`}
                                magnifierSize="50%"
                                largeImageSrc={`${item.thumbnailPresignedUrl}`}
                              />
                            </div>
                          ) : (
                            <StyledGrid container={true} spacing={0}>
                              <StyledNotAvailableImage>
                                {parcelType === "Diamond" ? (
                                  <StyledProductImage src="/images/default-diamond.png"></StyledProductImage>
                                ) : (
                                  <StyledProductImage src="/images/default-parcel.png"></StyledProductImage>
                                )}
                              </StyledNotAvailableImage>
                            </StyledGrid>
                          )}
                        </div>
                      ) : (
                        <div>
                          {item.thumbnailPresignedUrl === null ||
                            item.presignedUrl === null ? (
                            <StyledGrid container={true} spacing={0}>
                              <StyledNotAvailableImage>
                                <StyledProductImage src="/images/video_not_available.png"></StyledProductImage>
                              </StyledNotAvailableImage>
                            </StyledGrid>
                          ) : (
                            <div>
                              {item.mediaType === "VIDEO" ? (
                                <Grid container={true} spacing={0}>
                                  <StyledLargeTxtCon item={true} xs={12}>
                                    <StyledLargeTxtImg src="/images/video-enlarge.png"></StyledLargeTxtImg>
                                    <StyledLargeText
                                      onClick={() =>
                                        videoLargePopup(
                                          `${item.presignedUrl}`,
                                          item.mediaType
                                        )
                                      }
                                    >
                                      {t(
                                        "common:preview:productDetail:viewFullScreen"
                                      )}
                                    </StyledLargeText>
                                  </StyledLargeTxtCon>
                                  <PlayerSlide url={`${item.presignedUrl}`} />
                                </Grid>
                              ) : (
                                <Grid container={true} spacing={0}>
                                  <StyledLargeTxtCon item={true} xs={12}>
                                    <StyledLargeTxtImg src="/images/video-enlarge.png"></StyledLargeTxtImg>
                                    <StyledLargeText
                                      onClick={() =>
                                        videoLargePopup(
                                          `${item.presignedUrl}`,
                                          item.mediaType
                                        )
                                      }
                                    >
                                      {t(
                                        "common:preview:productDetail:viewFullScreen"
                                      )}
                                    </StyledLargeText>
                                  </StyledLargeTxtCon>
                                  {notSmallDevices ? (
                                    <StyledFrame
                                      src={`${item.presignedUrl}&sr=100`}
                                    />
                                  ) : (
                                    <div id="wrapfreamDetailsCarousel">
                                      <StyledFrame
                                        id="scaled-frameDetailsCarousel"
                                        src={`${item.presignedUrl}&sr=100`}
                                        style={{ margin: "0px" }}
                                      />
                                    </div>
                                  )}
                                </Grid>
                              )}
                            </div>
                          )}
                        </div>
                      )}
                    </StyledWrapperGrid>
                  ))}
                </Carousel>
              )}
            </StyledCorouselWrapper>
          ) : (
            <StyledGrid container={true} spacing={0}>
              <StyledNotAvailableImage>
                <StyledProductImage src="/images/default-diamond.png"></StyledProductImage>
              </StyledNotAvailableImage>
            </StyledGrid>
          )}
        </div>
      ) : (
        <StyledGrid container={true} spacing={0}>
          <StyledNotAvailableImage>
            <StyledProductImage src="/images/video_not_available.png"></StyledProductImage>
          </StyledNotAvailableImage>
        </StyledGrid>
      )}
      {onLargeVideo === true && (
        <StyledModal
          open={onLargeVideo}
          onClose={onClose}
          className="loginModal"
        >
          <ViewVideoEnlargeModal
            {...props}
            videoUrl={videoUrl}
            mediaTypeForFullView={mediaTypeForFullView}
          />
        </StyledModal>
      )}
    </StyledGrid>
  );
};

export default DetailsCarousel;
