import React from "react";
import { match } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import AppLayout from "../../../layouts/AppLayout";
import ApiTimeout from "../../../../modules/ApiTimeout";
import LoadingSpinner from "../../../../common/elements/LoadingSpinner";
import ProductDescription from "../../../shared/ProductDescription";
import DetailsNotes from "../../../products/details/DetailsNotes";
import {
  StyledGrid,
  StyledNoImgGrid,
  StyledNotAvailableImage,
  StyledProductImage,
} from "../../../products/details/DetailsCarousel";
import PerItemDescription from "../../../products/details/PerItemDescription";
import { StyledInquiryContainer } from "../../../products/details/inquiry/InquiryDetailsCss";
import BasicPopover from "../../../products/preview/exratemarquee/BasicPopover";
import { StyledModal } from "../../../../common/materials/GenericStyleComponent";
import Alert from "../../../../common/elements/Alert";
import BlockUserModal from "../../../products/BlockUser/BlockUserModal";
import ShareProductLinkView from "../../../products/ShareProductLinkView";
import ProductDetailsInquiry from "../../../products/details/inquiry/ProductDetailsInquiry";
import AuthService from "../../../auth/AuthService";
import IRouterComponentProps from "../../../../models/IRouterComponentProps";
import { oAuth } from "../../../../routes/basic/BasicAppRouter";
import copy from "copy-to-clipboard";
import Tooltip, { TooltipProps } from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";

import {
  StyledArrowIcons,
  StyledArrowIconsID,
  StyledArrowWrapper,
  StyledBackBtn,
  StyledBackBtnContainer,
  StyledBrandName,
  StyledButton,
  StyledContentWrapper,
  StyledGridWrapper,
  StyledItemDesc,
  StyledLabel,
  StyledLayoutGrid,
  StyledMyBidButton,
  StyledMyBidButtons,
  StyledName,
  StyledPrebidingContainer,
  StyledProductCarousalColumn,
  StyledProductName,
  StyledTitleContainer,
  StyledTooltipContainer,
  StyledTooltipText,
  StyledWrapper,
  StyledWrapperID,
} from "./DetailsPageCss";
import {
  annualAuctionProduct,
  jewelleryProductDetailsUrl,
  productUrl,
  watchlistUrl,
} from "../../../../common/config/app.endpoints";
import JewelleryPerItemDescription from "../../../products/details/JewelleryPerItemDescription";
import AnnualBidNowButton from "./AnnualBidNowButton";
import {
  getRapnetPercentage,
  joinAnnualAuction,
} from "../../../../common/GenericFunctions";
import AddorRemoveWatchlistAnnual from "../../../products/preview/AddorRemoveWatchlistAnnual";
import {
  StyledBtnContainer,
  StyledBuyitNowOnDetailsPage,
} from "../DiamondProductStyle";
import BuyItNowconfirmation from "../BuyItNow/BuyItNowconfirmation";
import ProductCarouselNew from "../../../products/newProductDetails/container/ProductCarouselNew";
import ProductCarousel365 from "../../../products/newProductDetails/container/ProductCarousel365";

interface DetailParams {
  auctionId: string;
  productId: string;
  auctionDate: string;
  auctionUserId?: string;
}

interface IState {
  items: any;
  openToast: boolean;
  showAlertMessage: string;
  index: number;
  isLoading: boolean;
  alertType: string;
  auctionProducts: Array<any>;
  totalElements: string | number;
  apiTimeout: boolean;
  userBlock: boolean;
  openShareModal: boolean;
  isPDView: boolean;
  count: number;
  fullData: any;
  openInquiry: boolean;
  showTenderSlider: boolean;
  isAuctionUserId: any;
  isBuyItNowModal: boolean;
  isBuyitnowThankyou: boolean;
}

interface IProductDetailsViewProps extends IRouterComponentProps {
  match: match<DetailParams>;
  t?: any;
  location: any;
}

let auctionDate: string = "";
let productList: [] = [];
let filteredValues: any = "";
let joinedAuctions: any = [];
let body: any = "";
let data = {};
var filter: [] | null | string = [];
var productID: any = "";
let isAuctionEnd: any;
let isSellerId: any = "";
let isReadOnlyUser: any;
const useStylesBootstrap = makeStyles(() => ({
  arrow: {
    cursor: "pointer",
    color: "#2d75c2",
  },
  tooltip: {
    fontSize: "12px",
    backgroundColor: "#2d75c2",
  },
}));

function TooltipContant(props: JSX.IntrinsicAttributes & TooltipProps) {
  const classes = useStylesBootstrap();
  return <Tooltip arrow classes={classes} {...props} />;
}
export default class CommonDetailsView extends React.Component<
  IProductDetailsViewProps,
  IState
> {
  constructor(props: IProductDetailsViewProps) {
    super(props);
    this.state = {
      items: "",
      openToast: false,
      showAlertMessage: "",
      index: 0,
      isLoading: false,
      alertType: "",
      auctionProducts: [],
      totalElements: "",
      apiTimeout: false,
      userBlock: false,
      openShareModal: false,
      isPDView: true,
      count: 0,
      fullData: "",
      openInquiry: false,
      showTenderSlider: true,
      isAuctionUserId: "",
      isBuyItNowModal: false,
      isBuyitnowThankyou: false,
    };
  }

  Auth = new AuthService();

  componentDidMount() {
    this.setState({
      isLoading: true,
    });
    data = {};
    body = "";
    filter = [];

    filteredValues = localStorage.getItem("filteredAnnualAucValues");
    filteredValues = JSON.parse(filteredValues);
    if (filteredValues !== null) {
      filteredValues.map((item: any, index: number) => {
        if (item.name === "auctionId") {
          filteredValues.splice(index, 1);
        }
      });
    }
    isReadOnlyUser = localStorage.getItem("isReadOnlyUser");
    isReadOnlyUser = JSON.parse(isReadOnlyUser);

    joinedAuctions = localStorage.getItem("joinedAnnualAuctions");
    joinedAuctions = JSON.parse(joinedAuctions);

    this.auctionProducts();
    joinAnnualAuction();
    this.getDetails(this.props.match.params.productId);
    window.addEventListener("keydown", this.keyHandling);

    const urlParams = new URLSearchParams(window.location.search);
    isAuctionEnd = urlParams.get("isAuctionend");
  }

  componentWillUnmount() {
    this.setState({
      items: "",
      openToast: false,
      showAlertMessage: "",
      index: 0,
      isLoading: false,
      alertType: "",
      auctionProducts: [],
      totalElements: "",
      apiTimeout: false,
      isAuctionUserId: "",
    });
    localStorage.removeItem("pathname");
    window.removeEventListener("keydown", this.keyHandling);
  }

  auctionProducts() {
    this.setState({
      openToast: false,
    });
    body =
      filteredValues === "null" || filteredValues === null
        ? filter
        : filteredValues;
    body.push({
      match: "exact",
      name: "auctionId",
      value: this.props.match.params.auctionId,
    });
    data = {
      page: {
        start: 0,
        count: 1000,
        sort: {
          orders: [
            {
              property: "serialNo",
              direction: "ASC",
            },
          ],
        },
      },
    };
    if (
      this.props.match.path ===
      "/watchlist/details/:auctionId/:productId/:auctionDate"
    ) {
      data = {
        page: {
          start: 0,
          count: 20,
          sort: {
            orders: [
              {
                property: "serialNo",
                direction: "ASC",
              },
            ],
          },
        },
      };
      localStorage.removeItem("optionValue");
    }
    if (!oAuth()) {
      this.props.history.push("/");
    } else {
      this.callApi(data, "notUpdatedProducts");
    }
  }

  getUpdatedProducts = (records: number) => {
    data = {
      page: {
        start: records,
        count: 1000,
        sort: {
          orders: [
            {
              property: "serialNo",
              direction: "ASC",
            },
          ],
        },
      },
    };
    if (
      this.props.match.path ===
      "/watchlist/details/:auctionId/:productId/:auctionDate"
    ) {
      data = {
        page: {
          start: records,
          count: 1000,
          sort: {
            orders: [
              {
                property: "serialNo",
                direction: "ASC",
              },
            ],
          },
        },
      };
    }
    if (!oAuth()) {
      this.props.history.push("/");
    } else {
      this.callApi(data, "updatedProducts");
    }
  };

  callApi(data: any, product: string) {
    const { t } = this.props;
    const urlParams = new URLSearchParams(window.location.search);
    // product Auction
    var url = "";
    if (
      this.props.match.path ===
      "/watchlist/details/:auctionId/:productId/:auctionDate"
    ) {
      if (urlParams.get("auctionType") === "Jewellery") {
        url =
          watchlistUrl +
          `?auctionId=${this.props.match.params.auctionId}&auctionType=Jewellery`;
      } else {
        url =
          watchlistUrl +
          `?auctionId=${this.props.match.params.auctionId}&auctionType=Diamonds`;
      }
    } else {
      url = url =
        annualAuctionProduct +
        `?auctionType=Annual&category=${urlParams.get("auctionType") === "Jewellery"
          ? "Jewellery"
          : "Diamonds"
        }`;
    }
    this.Auth.postRequest(`${url}`, data, (status: any, response: any) => {
      if (status === 200) {
        if (product === "updatedProducts") {
          var local: any = {};
          local = response.results;
          this.state.auctionProducts.push.apply(
            this.state.auctionProducts,
            local
          );
        } else {
          this.setState({
            auctionProducts: response.results,
          });
        }
        if (response.results.length === 0) {
          if (
            isAuctionEnd === null ||
            isAuctionEnd === undefined ||
            isAuctionEnd === ""
          ) {
            // this.props.history.push("/preview");
          }
        }
        this.setState({
          totalElements: response.totalElements,
        });
        this.getProducts(this.state.auctionProducts);
      } else if (status === 403) {
        this.setState({ userBlock: true });
      } else if (status === 404) {
        this.setState({
          alertType: "error",
          openToast: true,
          showAlertMessage: `${t("common:invalidRequest")}`,
          isLoading: false,
        });
      } else if (status === 505) {
        this.setState({
          apiTimeout: true,
        });
      } else {
        this.setState({
          alertType: "error",
          openToast: true,
          showAlertMessage: `${t("common:somethingWentWrong")}`,
          isLoading: false,
        });
      }
    });
  }

  getProducts(products: any) {
    productList = products;
    if (this.state.auctionProducts.length < this.state.totalElements) {
      this.getUpdatedProducts(this.state.auctionProducts.length);
    } else {
      this.checkProductId();
    }
  }

  checkProductId() {
    if (productList !== null) {
      productList.map((product: any, index: number) => {
        if (product.perId === this.props.match.params.productId) {
          this.setState({
            index: index,
          });
          this.getDetails(product.perId);
          productID = product.perId;
        }
      });
    }
  }

  getDetails = (productId: string | number) => {
    const urlParams = new URLSearchParams(window.location.search);
    let url: any = "";
    url =
      urlParams.get("auctionType") === "Jewellery"
        ? `${jewelleryProductDetailsUrl}/${productId}/detail`
        : `${productUrl}/${productId}/detail`;
    if (!oAuth()) {
      this.props.history.push("/");
    } else {
      this.Auth.getRequest(`${url}`, (status: number, response: any) => {
        localStorage.setItem("auctionState", response.auctionState);
        if (response?.auctionStartDate !== undefined) {
          auctionDate = response?.productStartDate.split(" ", 2);
          auctionDate = auctionDate[0];
        }
        this.setState({
          items: "",
        });
        if (status === 200) {
          if (response.subItems) {
            var rows = [];

            for (var i = 0; i < response.subItems.length; i++) {
              let obj = response.subItems[i];
              if (obj.medias !== undefined) {
                for (var j = 0; j < obj.medias.length; j++) {
                  rows.push(obj.medias[j]);
                }
              }
            }

            var objModify = response;
            objModify.medias = objModify.medias.concat(rows);

            this.setState({
              items: objModify,
              isLoading: false,
              fullData: response,
              count: 0,
            });
          } else {
            this.setState({
              items: response,
              isLoading: false,
              fullData: response,
              count: 0,
            });
          }
        } else if (status === 403) {
          this.setState({ userBlock: true });
        } else if (status === 505) {
          this.setState({
            apiTimeout: true,
            isLoading: false,
          });
        } else {
          this.setState({
            alertType: "error",
            openToast: true,
            showAlertMessage: response,
            isLoading: false,
          });
        }
      });
    }
  };

  keyHandling = (e: any) => {
    if (e.keyCode === 37) {
      this.previousButton();
    } else if (e.keyCode === 39) {
      this.nextArrow();
    }
  };

  previousButton = () => {
    const urlParams = new URLSearchParams(window.location.search);
    this.setState({
      openToast: false,
    });
    this.state.auctionProducts.map((product: any, index: number) => {
      if (this.state.index > 0) {
        var updatedIndex = this.state.index - 1;
        if (updatedIndex === index) {
          this.setState({
            index: index,
          });
          this.getDetails(product.perId);
          if (urlParams.get("auctionType") === "Jewellery") {
            localStorage.setItem(
              "currentJewelleryProductIdOfBidNow",
              product.perId
            );
          } else {
            localStorage.setItem("currentProductIdOfBidNow", product.perId);
          }
          if (
            this.props.match.path ===
            "/watchlist/details/:auctionId/:productId/:auctionDate"
          ) {
            this.props.history.push({
              pathname: `/watchlist/details/${product.auctionId}/${product.perId}/${auctionDate}`,
            });
          } else if (
            this.props.match.path ===
            "/home/365products/details/:auctionId/:auctionUserId/:productId/:auctionDate"
          ) {
            this.props.history.push({
              pathname: `/home/365products/details/${this.props.match.params.auctionId
                }/${this.props.match.params.auctionUserId}/${product.perId
                }/${auctionDate}?auctionType=${urlParams.get("auctionType") === "Jewellery"
                  ? "Jewellery"
                  : "Diamonds"
                }`,
            });
          } else {
            this.props.history.push({
              pathname: `/home/365products/details/${this.props.match.params.auctionId
                }/${this.props.match.params.auctionUserId}/${product.perId
                }/${auctionDate}?auctionType=${urlParams.get("auctionType") === "Jewellery"
                  ? "Jewellery"
                  : "Diamonds"
                }`,
            });
          }
        }
      }
    });
  };

  handleBackBtn = async () => {
    if (this.state.items && this.state.items.serialNo) {
      localStorage.setItem(
        "currentPDItem",
        JSON.stringify(this.state.items.serialNo)
      );
      let filteredValue = this.state.auctionProducts.filter(
        (value) => value.serialNo === this.state.items.serialNo
      );
      let index = this.state.auctionProducts.indexOf(filteredValue[0]);
      // login to check if user has increased items per page
      if (localStorage.getItem("annualitemsPerPage")) {
        let savedCount: any = localStorage.getItem("annualitemsPerPage");
        let endCount: number = parseInt(savedCount);
        localStorage.setItem("detailsitemsPerPage", savedCount);
        let sum = (index + 1) / endCount;
        let page = Math.ceil(sum);
        localStorage.setItem("currentPdPage", JSON.stringify(page));
      } else {
        let page = Math.ceil((index + 1) / 20);
        localStorage.setItem("currentPdPage", JSON.stringify(page));
      }
    }

    let val = localStorage.getItem("optionValue");
    if (val !== "" && val !== "null" && val != null) {
      let page = 1;
      localStorage.setItem("currentPdPage", JSON.stringify(page));
    }
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get("auctionType") === "Jewellery") {
      localStorage.removeItem("currentJewelleryProductIdOfBidNow");
    } else {
      localStorage.removeItem("currentProductIdOfBidNow");
    }
    let userIdforAuction = localStorage.getItem("userId");
    await this.renderAuctionPage(userIdforAuction);
  };

  bidNowRedirection = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    if (this.state.items && this.state.items.serialNo) {
      localStorage.setItem(
        "currentPDItem",
        JSON.stringify(this.state.items.serialNo)
      );
      let filteredValue = this.state.auctionProducts.filter(
        (value) => value.serialNo === this.state.items.serialNo
      );

      let index = this.state.auctionProducts.indexOf(filteredValue[0]);
      // login to check if user has increased items per page
      if (localStorage.getItem("annualitemsPerPage")) {
        let savedCount: any = localStorage.getItem("annualitemsPerPage");
        let endCount: number = parseInt(savedCount);
        localStorage.setItem("detailsitemsPerPage", savedCount);
        let sum = (index + 1) / endCount;
        let page = Math.ceil(sum);
        localStorage.setItem("currentPdPage", JSON.stringify(page));
      } else {
        let page = Math.ceil((index + 1) / 20);
        localStorage.setItem("currentPdPage", JSON.stringify(page));
      }
    }

    let val = localStorage.getItem("optionValue");
    if (val !== "" && val !== "null" && val != null) {
      let page = 1;
      localStorage.setItem("currentPdPage", JSON.stringify(page));
    }
    localStorage.setItem("perIdOfTable", productID);
    let userIdforAuction = localStorage.getItem("userId");

    if (urlParams.get("auctionType") === "Jewellery") {
      localStorage.setItem(
        "currentJewelleryProductIdOfBidNow",
        this.props.match.params.productId
      );
      if (joinedAuctions !== null) {
        joinedAuctions.filter((items: any, index: number) => {
          if (items.category === "Jewellery") {
            this.setState({
              isAuctionUserId: items.auctionUserId,
            });
          }
        });
      } else {
        this.setState({
          isAuctionUserId: "",
        });
      }
    } else {
      localStorage.setItem(
        "currentProductIdOfBidNow",
        this.props.match.params.productId
      );
      if (joinedAuctions !== null) {
        joinedAuctions.filter((items: any, index: number) => {
          if (items.category === "Diamonds") {
            this.setState({
              isAuctionUserId: items.auctionUserId,
            });
          }
        });
      } else {
        this.setState({
          isAuctionUserId: "",
        });
      }
    }

    await this.renderAuctionPage(userIdforAuction);
  };

  renderAuctionPage = (userIdforAuction: any) => {
    const urlParams = new URLSearchParams(window.location.search);
    if (userIdforAuction !== null) {
      this.props.history.push({
        pathname: `/365products/${urlParams.get("auctionType") === "Jewellery"
            ? "Jewellery"
            : "Diamonds"
          }`,
      });
    } else {
      this.props.history.push({
        pathname: `/365products/${urlParams.get("auctionType") === "Jewellery"
            ? "Jewellery"
            : "Diamonds"
          }`,
      });
    }
  };

  nextArrow = () => {
    this.setState({
      openToast: false,
    });
    const urlParams = new URLSearchParams(window.location.search);
    var updatedIndex = this.state.index + 1;
    this.state.auctionProducts.map((product: any, index: number) => {
      if (updatedIndex === index) {
        this.setState({
          index: index,
        });
        if (
          this.state.auctionProducts.length === index + 1 &&
          this.state.totalElements !== this.state.auctionProducts.length
        ) {
          if (updatedIndex < this.state.totalElements) {
            this.getUpdatedProducts(index + 1);
          }
        }
        this.getDetails(product.perId);

        if (urlParams.get("auctionType") === "Jewellery") {
          localStorage.setItem(
            "currentJewelleryProductIdOfBidNow",
            product.perId
          );
        } else {
          localStorage.setItem("currentProductIdOfBidNow", product.perId);
        }

        if (
          this.props.match.path ===
          "/watchlist/details/:auctionId/:productId/:auctionDate"
        ) {
          this.props.history.push({
            pathname: `/watchlist/details/${product.auctionId}/${product.perId}/${auctionDate}`,
          });
        } else if (
          this.props.match.path ===
          "/home/365products/details/details/:auctionId/:auctionUserId/:productId/:auctionDate"
        ) {
          this.props.history.push({
            pathname: `/home/365products/details/${this.props.match.params.auctionId
              }/${this.props.match.params.auctionUserId}/${product.perId
              }/${auctionDate}?auctionType=${urlParams.get("auctionType") === "Jewellery"
                ? "Jewellery"
                : "Diamonds"
              }`,
          });
        } else {
          this.props.history.push({
            pathname: `/home/365products/details/${this.props.match.params.auctionId
              }/${this.props.match.params.auctionUserId}/${product.perId
              }/${auctionDate}?auctionType=${urlParams.get("auctionType") === "Jewellery"
                ? "Jewellery"
                : "Diamonds"
              }`,
          });
        }
      }
    });
  };

  nextButtonSubItem = () => {
    if (this.state.fullData.subItems.length > this.state.count) {
      this.setState({
        items: this.state.fullData.subItems[this.state.count],
        count: this.state.count + 1,
      });
    }
  };

  previousButtonSubItem = () => {
    if (this.state.count === 1) {
      this.setState({
        items: this.state.fullData,
        count: this.state.count - 1,
      });
    } else if (this.state.fullData.subItems.length === this.state.count) {
      this.setState({
        items: this.state.fullData.subItems[this.state.count - 2],
        count: this.state.count - 1,
      });
    } else {
      if (this.state.count > 0) {
        this.setState({
          items: this.state.fullData.subItems[this.state.count - 2],
          count: this.state.count - 1,
        });
      }
    }
  };

  timeout = (timeout: boolean) => {
    if (timeout === true) {
      this.setState({
        apiTimeout: true,
      });
    }
  };

  alertType = (alertType: string, openToast: boolean, showMessage: string) => {
    this.setState({
      alertType: alertType,
      openToast: openToast,
      showAlertMessage: showMessage,
    });
  };

  blockCloseModal = () => {
    this.Auth.logout();
    this.props.history.replace("/");
  };

  shareButtonAction() {
    const urlParams = new URLSearchParams(window.location.search);
    const { t } = this.props;
    let languageCode =
      localStorage.getItem("language") === "EN"
        ? "en"
        : localStorage.getItem("language") === "zh_CN"
          ? "zh_CN"
          : "jp";
    if (urlParams.get("auctionType") === "Jewellery") {
      copy(
        `${window.location.hostname}/${languageCode}/product/tender/jewellery/${this.props.match.params.productId}/${auctionDate}/detail`
      );
    } else {
      copy(
        `${window.location.hostname}/${languageCode}/product/${this.props.match.params.productId}/${auctionDate}/detail`
      );
    }

    this.setState({
      alertType: "success",
      openToast: true,
      showAlertMessage: `${t("common:preview:linkCopied")}`,
      isLoading: false,
    });
  }

  onCloseModal = () => {
    this.setState({
      openShareModal: false,
      openInquiry: false,
    });
  };

  onCopyLink = () => {
    this.setState({
      openShareModal: false,
    });
  };

  oninquiry = () => {
    this.setState({
      openInquiry: true,
    });
  };

  getProductDetailDescription = () => {
    const code = this.state.fullData.code;
    let typeConditionDescription = "";
    if (
      this.state.fullData.jewelleryDetails !== undefined &&
      this.state.fullData.jewelleryDetails != null
    ) {
      typeConditionDescription = `${this.state.fullData.jewelleryDetails.jewelleryType} ${this.state.fullData.jewelleryDetails.jewelleryCondition} ${this.state.fullData.jewelleryDetails.jewelleryDescription}`;
    }
    return `${code} ${typeConditionDescription}`;
  };

  getMediasForCarosuel = () => {
    const medias = [...this.state.items.medias];
    for (let i = 0; i < medias.length; i++) {
      let mediaItem = medias[i];
      if (mediaItem.mediaType === "PHOTO") {
        if (mediaItem.primary === true) {
          medias.splice(i, 1);
          medias.unshift(mediaItem);
          break;
        }
      }
    }
    return medias;
  };

  convertedPlaceHolderValue = (
    currencyCodeVal: any,
    price: any,
    exchangeRate: any
  ) => {
    //get user currency code form local storeage
    let userCurrencyCode = localStorage.getItem("preferredCurrency");
    let userCurrencySymbol = localStorage.getItem("preferredCurrencySymbol");

    if (
      currencyCodeVal === undefined ||
      currencyCodeVal === null ||
      price === undefined ||
      price === null ||
      price === 0 ||
      exchangeRate === undefined ||
      exchangeRate === null
    ) {
      return " ";
    } else {
      if (userCurrencyCode !== currencyCodeVal.code) {
        const rate = exchangeRate?.[0]?.["rate"] ?? 1;
        let conversion = new Intl.NumberFormat("us-en").format(
          Math.round(price * rate)
        );
        let conversions = new Intl.NumberFormat("us-en").format(
          Math.round(price)
        );
        return `${conversions}${currencyCodeVal.symbol}  (${conversion} ${userCurrencySymbol})`;
      } else {
        let conversions = new Intl.NumberFormat("us-en").format(
          Math.round(price)
        );
        return `${conversions} ${userCurrencySymbol}`;
      }
    }
  };

  renderDisableBidNowButton = (t: any) => {
    if (isSellerId === this.state.fullData.sellerAuctionUserId) {
      return (
        <StyledMyBidButtons disabled style={{ background: "lightgray" }}>
          {t("common:inquiry:button:bidNow")}
        </StyledMyBidButtons>
      );
    } else {
      return (
        <StyledMyBidButton onClick={this.bidNowRedirection}>
          {t("common:inquiry:button:bidNow")}
        </StyledMyBidButton>
      );
    }
  };

  renderDescriptionByCategory = (descritpion: any) => {
    if (descritpion !== "" && descritpion?.parcelType === "Jewellery") {
      return (
        <StyledBrandName>
          <ProductDescription
            truncateLength={90}
            data={descritpion}
            isTendor={false}
          />
        </StyledBrandName>
      );
    } else {
      return (
        <StyledBrandName>
          <ProductDescription
            truncateLength={90}
            data={descritpion}
            isTendor={false}
          />
        </StyledBrandName>
      );
    }
  };

  renderSubDescriptionByCategory = (descritpion: any) => {
    if (descritpion !== "" && descritpion?.parcelType === "Jewellery") {
      return (
        <StyledProductName>
          <ProductDescription
            truncateLength={90}
            data={this.state.items}
            isTendor={false}
          />
        </StyledProductName>
      );
    } else {
      return (
        <StyledProductName>
          <ProductDescription
            truncateLength={90}
            data={this.state.items}
            isTendor={false}
          />
        </StyledProductName>
      );
    }
  };

  renderProductDetails = (dataVal: any) => {
    const { t } = this.props;
    if (dataVal !== "" && dataVal?.parcelType === "Jewellery") {
      return (
        <JewelleryPerItemDescription
          t={t}
          {...dataVal}
          auctionDate={auctionDate}
          isPDView={this.state.isPDView}
          onClickSignIn={() => {
            this.props.history.replace("/");
          }}
        />
      );
    } else {
      return (
        <PerItemDescription
          t={t}
          {...dataVal}
          auctionDate={auctionDate}
          isPDView={this.state.isPDView}
          onClickSignIn={() => {
            this.props.history.replace("/");
          }}
        />
      );
    }
  };

  renderBidNowButton = (dataVal: any) => {
    if (dataVal !== "" && dataVal?.parcelType === "Jewellery") {
      return (
        <AnnualBidNowButton
          {...this.props}
          {...this.state.items}
          bidNowRedirection={this.bidNowRedirection}
          isReadOnlyUser={isReadOnlyUser}
          isJewellery={true}
        />
      );
    } else {
      return (
        <AnnualBidNowButton
          {...this.props}
          {...this.state.items}
          bidNowRedirection={this.bidNowRedirection}
          isReadOnlyUser={isReadOnlyUser}
          isJewellery={false}
        />
      );
    }
  };

  renderGoback = () => {
    this.setState({
      isBuyItNowModal: false,
    });
    this.props.history.goBack();
  };

  renderBuyItNowConfirmationModal = () => {
    return (
      <StyledModal
        open={this.state.isBuyItNowModal}
        onClose={() => {
          this.setState({
            isBuyItNowModal: false,
          });
        }}
      >
        <BuyItNowconfirmation
          closeModal={this.renderGoback}
          itemDetails={this.state.items}
          auctionUserId={this.props.match.params.auctionUserId}
        />
      </StyledModal>
    );
  };

  render() {
    const { t } = this.props;
    return (
      <AppLayout {...this.props}>
        {this.state.apiTimeout === true ? (
          <ApiTimeout t={t} {...this.props} />
        ) : (
          <Grid>
            {this.state.isLoading === true ? (
              <div className="loader">
                <LoadingSpinner />
              </div>
            ) : (
              <StyledLayoutGrid container={true} spacing={0}>
                <StyledGridWrapper item={true} xs={12} xl={12} lg={12} md={12}>
                  <Grid
                    container={true}
                    spacing={0}
                    className="actionContainer"
                  >
                    <StyledBackBtnContainer item xs={12} sm={12} md={6} lg={8}>
                      <StyledBackBtn
                        onClick={this.handleBackBtn}
                        src="/images/controls/left_arrow_round_edges.png"
                      />
                      <span className="spanText" onClick={this.handleBackBtn}>
                        {t("common:preview:showAllProduct")}
                      </span>
                    </StyledBackBtnContainer>
                    <Grid item xs={12} sm={12} md={6} lg={4}>
                      <StyledWrapper container={true} spacing={0}>
                        <Grid item xs={8} sm={6} md={6} lg={6}>
                          <Grid container={true} spacing={0}>
                            {this.state.items !== "" &&
                              this.state.count === 0 && (
                                <StyledTooltipContainer item={true} xs={6}>
                                  <TooltipContant
                                    placement="top"
                                    disableTouchListener
                                    title={
                                      this.state.fullData.auctionType !==
                                        "Jewellery"
                                        ? `${t(
                                          "common:preview:productDetail:tendorshareTooltip"
                                        )}`
                                        : `${t(
                                          "common:preview:productDetail:tendorDiamondshareTooltip"
                                        )}`
                                    }
                                  >
                                    <StyledTooltipText>
                                      <StyledButton
                                        onClick={this.shareButtonAction.bind(
                                          this
                                        )}
                                      >
                                        {t(
                                          "common:preview:productDetail:share"
                                        )}
                                      </StyledButton>
                                    </StyledTooltipText>
                                  </TooltipContant>
                                </StyledTooltipContainer>
                              )}

                            <Grid item={true} xs={6}>
                              {this.state.items !== "" &&
                                this.state.count === 0 && (
                                  <TooltipContant
                                    placement="top"
                                    disableTouchListener
                                    title={t(
                                      "common:preview:productDetail:watchTooltip"
                                    )}
                                  >
                                    <StyledTooltipText>
                                      <AddorRemoveWatchlistAnnual
                                        {...this.props}
                                        t={t}
                                        {...this.state.items}
                                        getDetails={this.getDetails}
                                        alertType={this.alertType}
                                        apiTimeout={this.timeout}
                                        auctionType={
                                          this.state.fullData.auctionType
                                        }
                                        isReadOnlyUser={isReadOnlyUser}
                                      />
                                    </StyledTooltipText>
                                  </TooltipContant>
                                )}
                            </Grid>
                          </Grid>
                        </Grid>
                        <StyledArrowWrapper item xs={4} sm={6} md={6} lg={6}>
                          {this.state.index === 0 ? (
                            <StyledArrowIcons
                              onClick={this.previousButton}
                              src="/images/controls/left_arrow_round_edges_disabled.png"
                            />
                          ) : (
                            <StyledArrowIcons
                              onClick={this.previousButton}
                              src="/images/controls/left_arrow_round_edges.png"
                            />
                          )}
                          {productList.length - 1 === this.state.index ? (
                            <StyledArrowIcons
                              onClick={this.nextArrow}
                              src="/images/controls/right_arrow_round_edges_disabled.png"
                            />
                          ) : (
                            <StyledArrowIcons
                              onClick={this.nextArrow}
                              src="/images/controls/right_arrow_round_edges.png"
                            />
                          )}
                        </StyledArrowWrapper>
                      </StyledWrapper>
                    </Grid>
                    <StyledTitleContainer
                      item={true}
                      xs={12}
                      sm={12}
                      md={12}
                      lg={9}
                    >
                      {this.renderDescriptionByCategory(this.state.items)}
                    </StyledTitleContainer>{" "}
                    <StyledName item={true} xs={12} sm={12} md={12} lg={3}>
                      {this.state.items?.auctionName === undefined
                        ? " "
                        : this.state.items?.auctionName}
                    </StyledName>
                  </Grid>
                </StyledGridWrapper>
                <Grid item={true} xs={12}>
                  <StyledContentWrapper container={true} spacing={0}>
                    <StyledProductCarousalColumn
                      item={true}
                      xs={12}
                      sm={12}
                      md={6}
                      lg={6}
                      xl={6}
                    >
                      {this.state.items.medias !== undefined &&
                        this.state.items.medias.length > 0 ? (
                        <ProductCarousel365
                          medias={this.getMediasForCarosuel()}
                          productData={this.state.items}
                        />
                      ) : (
                        <StyledNoImgGrid container={true} spacing={0}>
                          <StyledNotAvailableImage>
                            <StyledProductImage src="/images/default-diamond.png"></StyledProductImage>
                          </StyledNotAvailableImage>
                        </StyledNoImgGrid>
                      )}
                    </StyledProductCarousalColumn>
                    <Grid item={true} xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Grid item={true} xs={12} sm={12}>
                        <Grid container={true} spacing={0}>
                          <Grid item={true} xs={6} xl={6} lg={6}>
                            {this.state.items !== "" && (
                              <DetailsNotes
                                t={t}
                                {...this.props}
                                {...this.state.items}
                                count={this.state.count}
                                apiTimeout={this.timeout}
                                noteSaved={() =>
                                  this.getDetails(
                                    this.props.match.params.productId
                                  )
                                }
                                auctionType={this.state.fullData.auctionType}
                                isReadOnlyUser={isReadOnlyUser}
                              />
                            )}
                            {this.state.fullData.note !== "" && (
                              <StyledLabel>
                                {this.state.fullData.note}
                              </StyledLabel>
                            )}
                          </Grid>
                          <Grid
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginTop: "50px",
                              gap: "30px",
                            }}
                          >
                            <Grid>
                              {this.state.items?.buyItNow !== 0 ? (
                                <div style={{ color: "red" }}>
                                  {this.state.items !== undefined &&
                                    this.state.items !== null && (
                                      <span>
                                        {this.convertedPlaceHolderValue(
                                          this.state.items.currency,
                                          this.state.items.buyItNow,
                                          this.state.items.exchangeRates
                                        )}
                                      </span>
                                    )}
                                </div>
                              ) : null}
                            </Grid>

                            <Grid>
                              {this.state.items !== undefined &&
                                this.state.items !== null && (
                                  <StyledBtnContainer>
                                    {this.state.items.buyItNow === 0 ||
                                      this.state.items.sellerAuctionUserId ===
                                      this.props.match.params.auctionUserId ||
                                      isReadOnlyUser ||
                                      this.state.items.buyItNow <
                                      this.state.items.currentBidPrice ? (
                                      <StyledBuyitNowOnDetailsPage
                                        style={{
                                          color: "black",
                                          backgroundColor: "lightgrey",
                                        }}
                                      >
                                        Buy it Now
                                      </StyledBuyitNowOnDetailsPage>
                                    ) : (
                                      <StyledBuyitNowOnDetailsPage
                                        onClick={() => {
                                          this.setState({
                                            isBuyItNowModal: true,
                                          });
                                        }}
                                      >
                                        Buy it Now
                                      </StyledBuyitNowOnDetailsPage>
                                    )}
                                  </StyledBtnContainer>
                                )}
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item={true} xs={12}>
                          <Grid
                            item={true}
                            xs={12}
                            md={12}
                            xl={10}
                            lg={10}
                            spacing={0}
                          >
                            <StyledPrebidingContainer>
                              <div
                                style={{
                                  fontSize: "1.4em",
                                  fontWeight: "bold",
                                  display: "flex",
                                  flexDirection: "row",
                                  gap: "5px",
                                }}
                              >
                                <span>
                                  {" "}
                                  {t(
                                    "common:preview:perItemDescription:startingPrice"
                                  )}{" "}
                                </span>
                                {this.state.items !== undefined &&
                                  this.state.items !== null && (
                                    <span>
                                      {this.convertedPlaceHolderValue(
                                        this.state.items.currency,
                                        this.state.items.caratMinimumPrice,
                                        this.state.items?.exchangeRates
                                      )}
                                    </span>
                                  )}
                              </div>
                              {this.state.items?.parcelType !== "Jewellery" ? (
                                <div>
                                  {this.state.items !== undefined &&
                                    this.state.items !== null && (
                                      <span>
                                        {getRapnetPercentage(
                                          this.state.items.rapnetPercentage,
                                          this.state.items?.exchangeRates,
                                          this.state.items?.currency,
                                          this.state.items?.caratMinimumPrice,
                                          this.state.items?.rapnetPrice
                                        )}
                                      </span>
                                    )}
                                </div>
                              ) : null}
                              {this.state.items?.parcelType !== "Jewellery" ? (
                                <div>
                                  <span>Amount: </span>
                                  {this.state.items !== undefined &&
                                    this.state.items !== null && (
                                      <span>
                                        {this.convertedPlaceHolderValue(
                                          this.state.items.currency,
                                          this.state.items.startingPrice,
                                          this.state.items.exchangeRates
                                        )}
                                      </span>
                                    )}
                                </div>
                              ) : null}

                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "end",
                                }}
                              >
                                <Grid
                                  style={{
                                    justifyContent: "end",
                                    display: "flex",
                                    marginTop: "10px",
                                  }}
                                >
                                  {this.state.items !== "" &&
                                    this.state.count === 0 && (
                                      <AnnualBidNowButton
                                        {...this.props}
                                        {...this.state.items}
                                        bidNowRedirection={
                                          this.bidNowRedirection
                                        }
                                        isReadOnlyUser={isReadOnlyUser}
                                        isJewellery={
                                          this.state.items?.parcelType ===
                                            "Jewellery"
                                            ? true
                                            : false
                                        }
                                      />
                                    )}
                                </Grid>
                              </div>
                            </StyledPrebidingContainer>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </StyledContentWrapper>
                </Grid>
                <Grid item={true} xs={12}>
                  <StyledWrapperID item={true} xs={12}>
                    <StyledItemDesc item={true} xs={12}>
                      {t("common:preview:productDetail:itemDescription")}
                      {": "}
                      {this.renderSubDescriptionByCategory(this.state.fullData)}
                    </StyledItemDesc>
                    {this.state.fullData.subItems != null && (
                      <>
                        {this.state.count === 0 ? (
                          <StyledArrowIconsID
                            onClick={this.previousButtonSubItem}
                            src="/images/controls/left_arrow_round_edges_disabled.png"
                          />
                        ) : (
                          <StyledArrowIconsID
                            onClick={this.previousButtonSubItem}
                            src="/images/controls/left_arrow_round_edges.png"
                          />
                        )}
                        {this.state.fullData.subItems.length ===
                          this.state.count ? (
                          <StyledArrowIconsID
                            onClick={this.nextButtonSubItem}
                            src="/images/controls/right_arrow_round_edges_disabled.png"
                          />
                        ) : (
                          <StyledArrowIconsID
                            onClick={this.nextButtonSubItem}
                            src="/images/controls/right_arrow_round_edges.png"
                          />
                        )}
                      </>
                    )}
                  </StyledWrapperID>
                  {this.renderProductDetails(this.state.items)}
                  <StyledInquiryContainer>
                    <BasicPopover oninquiryFun={this.oninquiry} />
                  </StyledInquiryContainer>
                </Grid>
              </StyledLayoutGrid>
            )}
          </Grid>
        )}

        {this.state.openToast === true && (
          <Alert
            showAlertMessage={this.state.showAlertMessage}
            alertType={this.state.alertType}
          />
        )}
        {this.state.userBlock === true && (
          <StyledModal open={this.state.userBlock}>
            <BlockUserModal
              t={t}
              {...this.props}
              closeModal={this.blockCloseModal}
            />
          </StyledModal>
        )}
        {this.state.openShareModal === true && (
          <StyledModal
            open={this.state.openShareModal}
            onClose={this.onCloseModal}
          >
            <ShareProductLinkView
              onCopyModal={this.onCopyLink}
              onCancelModal={this.onCloseModal}
              t={t}
            />
          </StyledModal>
        )}

        {this.state.openInquiry === true && (
          <StyledModal
            open={this.state.openInquiry}
            onClose={this.onCloseModal}
          >
            <ProductDetailsInquiry
              t={t}
              data={this.state.items}
              closeModal={this.onCloseModal}
              auctionId={this.props.match.params.auctionId}
            />
          </StyledModal>
        )}

        {this.state.isBuyItNowModal && this.renderBuyItNowConfirmationModal()}
      </AppLayout>
    );
  }
}
