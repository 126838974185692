import React from "react";
import Grid from "@material-ui/core/Grid";
import { match } from "react-router-dom";
import SockJS from "sockjs-client";
import Stomp from "stompjs";
import CountUp from "react-countup";
import { v4 as uuidv4 } from "uuid";
import PubSub from "pubsub-js";
import Countdown from "react-countdown";
import moment from "moment";
import {
  StyledAuctionEndedWrapper,
  StyledBiddingPriceContainer,
  StyledBreadkPopup,
  StyledCardContainer,
  StyledComponent,
  StyledContainer,
  StyledCurrentBid,
  StyledDetailTitle,
  StyledDisableButton,
  StyledGrid,
  StyledImage,
  StyledMessage,
  StyledModal,
  StyledPaddleBox,
  StyledTextWrapper,
  StyledTimer,
  StyledTypo,
  StyledWrapper,
  StyledWrapperPopUp,
  StyledinnerGrid,
} from "../../AuctionLivePageStyles";
import AuctionDescription from "../../AuctionDescription";
import {
  auctionWebsocketUrl,
  joinAuctionUrl,
  postErrorLog,
} from "../../../../../common/config/app.endpoints";
import IRouterComponentProps from "../../../../../models/IRouterComponentProps";
import CircularStatic from "../../../../TimerNew";
import AuthService from "../../../../auth/AuthService";
import AppLayout from "../../../../layouts/AppLayout";
import BlockUserModal from "../../../../products/BlockUser/BlockUserModal";
import AboutToStart from "../../../../products/auction/AboutToStart";
import AuctionCancelled from "../../../../products/auction/AuctionCancelled";
import ViewConnectLostModal from "../../../../products/auction/ViewConnectLostModal";
import ViewEndedModal from "../../../../products/auction/ViewEndedModal";
import ViewHoldModal from "../../../../products/auction/ViewHoldModal";
import ViewPausedModal from "../../../../products/auction/ViewPausedModal";
import ViewSoldOutModal from "../../../../products/auction/ViewSoldOutModal";
import ViewWinnerModal from "../../../../products/auction/ViewWinnerModal";
import CardPriceSection from "../details/CardPriceSection";
import CertificationPopup from "../details/CertificationPopup";
import {
  getTimeDifference,
  openJewelleryCertificateLinkPopup,
} from "../../../../../common/GenericFunctions";
import AlertReadOnlyUser from "../../../../../common/elements/AlertReadOnlyUser";
import LoadingSpinner from "../../../../../common/elements/LoadingSpinner";
import BidingDetailsCarousel from "../../../../products/details/BidingDetailsCarousel";
import Sound from "../details/Sound";
import {
  convertedPlaceHolderValue,
  getProductDescription,
} from "../../AuctionCommonServices";
import NextProduct from "../../../../products/details/NextProduct";

interface DetailParams {
  auctionId: string;
  auctionName: string;
  perId: any;
  auctionDate: string;
  totalProducts: string;
}

interface IProps extends IRouterComponentProps {
  match: match<DetailParams>;
  t?: any;
}

interface IState {
  openSoldOutModal: boolean;
  openWinnerModal: boolean;
  auctionDetail: any;
  counterSeconds: any;
  preBidAmount: number | string;
  note: string;
  openEndedModal: boolean;
  openHoldModal: boolean;
  openPauseModal: boolean;
  changeColor: any;
  watched: boolean;
  isPaused: boolean;
  bidAmount: number;
  openCancelModal: boolean;
  openAboutToStart: boolean;
  isbuttonDisable: boolean;
  rapnetPercentage: number | string;
  rapnetPrice: number | string;
  isConnectionLost: boolean;
  openBlockModal: boolean;
  isBidIncrease: boolean;
  isSeller: boolean;
  action: string;
  isPlaySound: boolean;
  isPlayOnBtn: boolean;
  remarks: string;
  exchangeRate: string;
  isParcelUIShow: boolean;
  isAuctionBreak: boolean;
  isOpenPopup: boolean;
  isLoading: boolean;
  button1: number;
  button2: number;
  button3: number;
  auctionCurrency: string;
  conversionPreBidAmount: number | string;
  conversionCaratMinPrice: number | string;
  isShowBreakMsg: boolean;
  exchangeFieldTitle: string;
  currency: any;
  caratMinimumPrice: any;
  exchangeRateInUsd: string;
  openReadOnlyUserToast: boolean;
  isShowPaddleBoard: boolean; //adding new state variable for paddle number
  paddleNumbertobeShow: number | string;
}

let counter: any = null;
let preProductId = 0;
let productId: any = null;
let serialNo: any = null;
let currentBidPrice: any = null;
let userId: string | null = "";
let paddleNumber: any; // added new variable for paddle number
let auctionId: string | number = "";
let startTime: any = "";
let productAction: string = "";
let seconds: any = "";
let reverseRate: any = null;
let productPricePerCaratUSD: any = null;
let medias: any = [];
let soldBidPrice: any = null;
let storeAuctionDetails: any = null;
let shortbreakTimerShow: any = "";

let date: string | null = "";
let time: string | null = "";
let timezone: any = "";
let hour: number | null | string = "";
let popUpseconds: any = "";
let minutes: any = "";
let timeDifference: any = "";
let productNoLbl: any = "";
let isReadOnlyUser: any;
let maxRetry: any = 1;
export default class NewJewelleryLiveAuctionContainer extends React.Component<
  IProps,
  IState
> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      openSoldOutModal: false,
      openWinnerModal: false,
      auctionDetail: {},
      counterSeconds: "",
      preBidAmount: "",
      note: "",
      openEndedModal: false,
      openPauseModal: false,
      openHoldModal: false,
      changeColor: "black",
      watched: false,
      isPaused: false,
      bidAmount: 0,
      openCancelModal: false,
      openAboutToStart: false,
      isbuttonDisable: false,
      rapnetPercentage: "",
      rapnetPrice: "",
      isConnectionLost: false,
      openBlockModal: false,
      isBidIncrease: false,
      isSeller: false,
      action: "",
      isPlaySound: false,
      isPlayOnBtn: false,
      remarks: "",
      exchangeRate: "",
      isParcelUIShow: false,
      isAuctionBreak: false,
      isOpenPopup: false,
      isLoading: true,
      button1: 0,
      button2: 0,
      button3: 0,
      auctionCurrency: "",
      conversionPreBidAmount: "",
      conversionCaratMinPrice: "",
      isShowBreakMsg: false,
      exchangeFieldTitle: "",
      currency: "",
      caratMinimumPrice: "",
      exchangeRateInUsd: "",
      openReadOnlyUserToast: false,
      isShowPaddleBoard: false,
      paddleNumbertobeShow: "-",
    };
  }
  Auth = new AuthService();
  client: any;
  startingAmount: any = "";
  isconnected = false;
  sock = new SockJS(auctionWebsocketUrl);
  isUnmount: any = false;

  componentDidMount() {
    this.isUnmount = false;
    getTimeDifference();

    this.setState({
      bidAmount: 0,
      isbuttonDisable: true,
    });

    isReadOnlyUser = localStorage.getItem("isReadOnlyUser");
    isReadOnlyUser = JSON.parse(isReadOnlyUser);

    if (
      this.props &&
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.auctionId
    ) {
      auctionId = this.props.match.params.auctionId;
    }
    this.initNetworkEvents();
    this.connectToWebSocket();
    this.Auth.getRequest(
      `${joinAuctionUrl}/${auctionId}/productLikings`,
      (status: any, response: any) => {
        //localStorage.setItem('AuctionDetails', JSON.stringify(response.results));
        storeAuctionDetails = response.results;
      }
    );

    PubSub.publish("StopStartTimer", "Stop");
  }

  initNetworkEvents() {
    window.addEventListener("online", this.handleConnectionChange);
    window.addEventListener("offline", this.handleConnectionChange);
  }

  handleConnectionChange = () => {
    const that = this;
    const condition = navigator.onLine ? "online" : "offline";
    that.triggerReconnect(condition);
  };

  triggerReconnect(eventName: string) {
    const that = this;

    // This will trigger the "onWebSocketClose" method in stompClient
    that.sock.close();
  }

  reConnectWebSocket() {
    var that = this;
    const retryTimeout = 1;
    if (that.isconnected) {
      that.isconnected = false;
      localStorage.setItem(
        "WSConnectionBreakTime",
        `${moment(Date.now()).format("YYYY-MM-DD hh:mm:ss a")}`
      );
    }
    // Call the websocket connect method
    if (!that.isUnmount) {
      setTimeout(function () {
        if (maxRetry <= 5) {
          that.connectToWebSocket();
        } else {
          that.props.history.push("/auction");
        }
        maxRetry++;
      }, retryTimeout * 2000);
    }
  }

  postLog() {
    var that = this;
    const data = {
      page: "auction",
      section: "",
      errorMessage: `Web-socket disconnect at ${localStorage.getItem(
        "WSConnectionBreakTime"
      )} and reconnect at ${moment(Date.now()).format(
        "YYYY-MM-DD hh:mm:ss a"
      )}`,
      errorCode: "",
      type: "Error",
      source: "BidderWebsite",
    };

    that.Auth.postRequest(
      postErrorLog,
      data,
      (status: number, response: any) => {
        localStorage.removeItem("WSConnectionBreakTime");
      }
    );
  }

  componentWillUnmount() {
    this.isUnmount = true;
    this.setState({
      openSoldOutModal: false,
      openWinnerModal: false,
      auctionDetail: {},
      openEndedModal: false,
      openPauseModal: false,
      openHoldModal: false,
      changeColor: "black",
      isPaused: false,
      bidAmount: 0,
      openCancelModal: false,
      openAboutToStart: false,
      isConnectionLost: false,
      isAuctionBreak: false,
      isOpenPopup: false,
      isLoading: true,
    });

    if (
      auctionId !== undefined &&
      auctionId !== null
    ) {
      var subscription: any = this.client.subscribe("/topic/bid." + auctionId);
      subscription.unsubscribe();
      this.client.disconnect();
    }

    PubSub.publish("StopStartTimer", "Start");
    window.removeEventListener("online", this.handleConnectionChange);
    window.removeEventListener("offline", this.handleConnectionChange);
  }

  connectToWebSocket() {
    var that = this;
    userId = localStorage.getItem("userId");
    paddleNumber = localStorage.getItem("paddleNumber");
    that.sock = new SockJS(auctionWebsocketUrl);
    that.client = Stomp.over(that.sock);
    if (userId !== null && userId !== undefined) {
      that.client.connect(
        {
          token: that.Auth.getToken(),
          auction_id: auctionId,
          auction_user_id: userId,
        },
        (message: any) => {
          that.client.subscribe(
            "/topic/bid." + auctionId,
            (productResponse: any) => {
              that.isconnected = true;
              that.subscribe(productResponse);
              if (localStorage.getItem("WSConnectionBreakTime")) {
                that.postLog();
              }
            },
            () => {
              that.setState({
                isConnectionLost: true,
              });
            }
          );
          if (productAction !== "Auction_Ended") {
            this.onjoin();
          }
        },
        (error: any) => {
          console.log(error, "msg of err");
          that.reConnectWebSocket();
        }
      );
      // Error handlers. There are many other methods also
      this.client.onDisconnect = () => {
        that.reConnectWebSocket();
      };
      this.client.onStompError = () => {
        that.reConnectWebSocket();
      };
      // This is the most important
      this.client.onWebSocketClose = () => {
        that.reConnectWebSocket();
      };
    } else {
      console.log("******auction_user_id ID NULL******");
      this.props.history.push("/auction");
    }
  }

  subscribe(productResponse: any) {
    this.setState({
      isLoading: false,
      isbuttonDisable: false,
    });
    this.startingAmount = "";
    let productBidResponse = JSON.parse(productResponse.body);
    productAction = productBidResponse.action;
    this.setState({
      action: productBidResponse.action,
    });
    startTime = productBidResponse.start_time;

    if (productBidResponse?.product?.seller_auction_user_id === userId) {
      this.setState({
        isbuttonDisable: true,
        isSeller: true,
      });
    }

    if (productAction !== "Auction_Break_MSG") {
      clearInterval(counter);
    }

    if (productAction === "User_Deactivated") {
      if (productBidResponse.auction_user_id === userId) {
        this.setState({ openBlockModal: true });
      }
    } else {
      if (productBidResponse.product !== undefined) {
        let userCurrencyCode = localStorage.getItem("preferredCurrency");

        var exchangeRatetitle = "";

        if (userCurrencyCode !== productBidResponse.product.currency.code) {
          exchangeRatetitle = `In ${userCurrencyCode}`;
        }

        this.setState({
          auctionCurrency: productBidResponse.product.currency.symbol,
          exchangeFieldTitle: exchangeRatetitle,
        });
        // current_bid_price;

        if (
          productBidResponse.product.parcel_type !== undefined &&
          productBidResponse.product.parcel_type === "Jewellery"
        ) {
          productPricePerCaratUSD =
            productBidResponse.product.current_bid_price *
            productBidResponse.product.exchange_rates[0].rate;
          currentBidPrice = productBidResponse.product.current_bid_price;
          if (productBidResponse.product.currency.code === "JPY") {
            if (currentBidPrice < 10000) {
              this.setState({
                isParcelUIShow: true,
                button1: 100,
                button2: 500,
                button3: 1000,
              });
            } else if (currentBidPrice >= 10000 && currentBidPrice < 100000) {
              this.setState({
                isParcelUIShow: true,
                button1: 1000,
                button2: 5000,
                button3: 10000,
              });
            } else if (currentBidPrice >= 100000) {
              this.setState({
                isParcelUIShow: true,
                button1: 1000,
                button2: 5000,
                button3: 10000,
              });
            }
          } else {
            if (currentBidPrice < 100) {
              this.setState({
                isParcelUIShow: true,
                button1: 1,
                button2: 5,
                button3: 10,
              });
            } else if (currentBidPrice >= 100 && currentBidPrice < 1000) {
              this.setState({
                isParcelUIShow: true,
                button1: 10,
                button2: 50,
                button3: 100,
              });
            } else if (currentBidPrice >= 1000 && currentBidPrice < 30000) {
              this.setState({
                isParcelUIShow: true,
                button1: 10,
                button2: 50,
                button3: 100,
              });
            } else if (currentBidPrice >= 30000) {
              this.setState({
                isParcelUIShow: true,
                button1: 1000,
                button2: 5000,
                button3: 10000,
              });
            }
          }
        }
      }

      if (productBidResponse.action === "Auction_AboutToStart") {
        this.setState({
          openAboutToStart: true,
          isAuctionBreak: false,
          isShowPaddleBoard: false,
        });
      }

      if (
        productBidResponse.action === "Auction_Ended" ||
        productBidResponse.action === "Auction_Cancelled"
      ) {
        if (counter) {
          clearInterval(counter);
        }
        this.setState({
          isPaused: false,
          openSoldOutModal: false,
          openWinnerModal: false,
          openPauseModal: false,
          openHoldModal: false,
          isAuctionBreak: false,
          isbuttonDisable: true,
        });
        if (productBidResponse.action === "Auction_Ended") {
          this.setState({
            openEndedModal: true,
          });
        } else {
          this.setState({
            openCancelModal: true,
          });
        }
      }
      if (
        productBidResponse.action !== "Auction_AboutToStart" &&
        productAction !== "Auction_Ended" &&
        productAction !== "Auction_Cancelled"
      ) {
        this.setState({
          //auctionDetail: {},
          isBidIncrease: false,
          isSeller: false,
          isShowPaddleBoard: true,
          paddleNumbertobeShow: productBidResponse.paddle_number,
        });
        if (
          productBidResponse !== undefined &&
          productBidResponse.product !== undefined &&
          productBidResponse.product.auction_user_id !== undefined
        ) {
          if (productBidResponse.product.auction_user_id !== userId) {
            this.setState({
              changeColor: "black",
            });
          } else {
            if (productBidResponse.color === "GREEN") {
              this.setState({
                changeColor: "green",
              });
            } else if (productBidResponse.color === "RED") {
              this.setState({
                changeColor: "red",
              });
            } else {
              this.setState({
                changeColor: "black",
              });
            }
          }
        } else {
          this.setState({
            changeColor: "black",
          });
        }

        if (productBidResponse.action === "NEXT_PRODUCT") {
          this.setState({
            auctionDetail: {},
            openAboutToStart: false,
          });
        }

        this.showProductDetails(productBidResponse.product);
      }
      if (
        productAction !== "Auction_Paused" &&
        productAction !== "Auction_Ended" &&
        productAction !== "CURRENT_STATE" &&
        productAction !== "Auction_Break_MSG"
      ) {
        this.setState({
          bidAmount: this.state.auctionDetail.current_bid_price,
          counterSeconds: "-",
        });
      }
      if (productBidResponse.action === "CURRENT_STATE") {
        this.setState({
          isPaused: false,
          isAuctionBreak: false,
          openAboutToStart: false,
        });
        localStorage.setItem("action", "null");
        this.startCounter(startTime);
        if (productBidResponse.product.auction_user_id === userId) {
          this.setState({
            isbuttonDisable: true,
          });
        }
        if (
          productBidResponse.product.product_pre_bids !== undefined &&
          productBidResponse.product.product_pre_bids.length > 0
        ) {
          for (
            var i = 0;
            i < productBidResponse.product.product_pre_bids.length;
            i++
          ) {
            this.setState({
              bidAmount:
                productBidResponse.product.product_pre_bids[i].applied_price,
            });
          }
        } else {
          this.setState({
            bidAmount: productBidResponse.product.current_bid_price,
          });
        }

        //when short break schedule and user click on refresh
        if (
          productBidResponse.product.upcoming_product_on_break_scheduled !==
          undefined
        ) {
          if (
            productBidResponse.product.upcoming_product_on_break_scheduled ===
            true
          ) {
            this.setState({
              isShowBreakMsg: true,
            });
            productNoLbl =
              productBidResponse.product.upcoming_product_on_break_serial_no;
            this.resumeTimeUpdateHandler(productBidResponse);
          } else {
            this.setState({
              isShowBreakMsg: false,
            });
          }
        }
      } else if (
        productBidResponse.action === "NEXT_PRODUCT" &&
        productId !== null &&
        preProductId !== productId
      ) {
        preProductId = productId;
        console.log("NEXT_PRODUCT 2 preProductId" + preProductId);
        if (counter) {
          clearInterval(counter);
        }
        this.setState({
          isPaused: false,
          isAuctionBreak: false,
          openAboutToStart: false,
          isbuttonDisable: true,
          isPlaySound: true,
          openWinnerModal: false,
        });
        if (
          productBidResponse.product.product_pre_bids !== undefined &&
          productBidResponse.product.product_pre_bids.length > 0
        ) {
          for (
            var j = 0;
            j < productBidResponse.product.product_pre_bids.length;
            j++
          ) {
            this.setState({
              bidAmount:
                productBidResponse.product.product_pre_bids[j].applied_price,
            });
          }
        } else {
          this.setState({
            bidAmount: productBidResponse.product.current_bid_price,
          });
        }
        localStorage.setItem("action", "null");
        this.startCounter(startTime);
      } else if (productBidResponse.action === "BID") {
        this.setState({
          isbuttonDisable: true,
          openAboutToStart: false,
          bidAmount: productBidResponse.product.current_bid_price,
          paddleNumbertobeShow: productBidResponse.paddle_number,
          isShowPaddleBoard: true,
        });

        //when short break schedule and user is doing bid
        if (
          productBidResponse.product.upcoming_product_on_break_scheduled !==
          undefined
        ) {
          if (
            productBidResponse.product.upcoming_product_on_break_scheduled ===
            true
          ) {
            this.setState({
              isShowBreakMsg: true,
            });
            productNoLbl =
              productBidResponse.product.upcoming_product_on_break_serial_no;
            this.resumeTimeUpdateHandler(productBidResponse);
          } else {
            this.setState({
              isShowBreakMsg: false,
            });
          }
        }

        if (productBidResponse.product.auction_user_id === userId) {
          if (productBidResponse.color === "GREEN") {
            this.setState({
              changeColor: "green",
            });
          } else if (productBidResponse.color === "RED") {
            this.setState({
              changeColor: "red",
            });
          } else {
            this.setState({
              changeColor: "black",
            });
          }
        } else {
          this.setState({
            changeColor: "black",
          });
        }
        if (counter) {
          clearInterval(counter);
        }
        clearInterval(counter);
        this.setState({
          isPaused: false,
          isAuctionBreak: false,
        });
        localStorage.setItem("action", "null");
        this.startCounter(startTime);
      }
      if (productBidResponse.action === "HOLD") {
        if (counter) {
          clearInterval(counter);
        }
        this.setState({
          isPaused: false,
          isbuttonDisable: true,
          isAuctionBreak: false,
        });
        if (productBidResponse.product.auction_user_id === userId) {
          this.setState({
            openHoldModal: true,
          });
        } else {
          soldBidPrice = currentBidPrice;
          this.setState({
            openSoldOutModal: true,
          });
        }
        productAction = productBidResponse.action;
        localStorage.setItem("action", "null");
      } else if (productBidResponse.action === "SOLD") {
        clearInterval(counter);
        this.setState({
          isPaused: false,
          changeColor: "black",
          bidAmount: 0,
          isbuttonDisable: true,
          isAuctionBreak: false,
        });
        if (productBidResponse.product.auction_user_id === userId) {
          this.setState({
            openWinnerModal: true,
          });
        } else {
          soldBidPrice = currentBidPrice;
          this.setState({
            openSoldOutModal: true,
          });
        }
        localStorage.setItem("action", "null");
      } else if (productAction === "Auction_Paused") {
        var stored = localStorage.getItem("action");
        if (stored === null || stored === "null") {
          this.setState({
            openPauseModal: true,
          });
        } else {
          this.setState({
            openPauseModal: false,
          });
        }
        this.setState({
          isPaused: true,
          isAuctionBreak: false,
          bidAmount: productBidResponse.product.current_bid_price,
        });
        if (productBidResponse.product.auction_user_id === userId) {
          this.setState({
            isbuttonDisable: true,
          });
        }
        localStorage.setItem("action", "paused");
        clearInterval(counter);
        this.startCounter(startTime);
      } else if (productAction === "Auction_Break_MSG") {
        if (timezone !== null) {
          timezone = localStorage.getItem("timezoneName");
        }
        if (
          productBidResponse.req_msg_id !== undefined &&
          productBidResponse.req_msg_id !== null
        ) {
          productNoLbl = productBidResponse.req_msg_id;
          let shortBreakTimer = moment.utc(productBidResponse.resume_time);
          if (shortBreakTimer !== null || shortBreakTimer !== undefined) {
            let result = moment.tz(shortBreakTimer, timezone);
            let resultFormatter = result.format();
            if (resultFormatter !== "Invalid date") {
              let splitDateTime = resultFormatter.split("T", 2);
              let splitTime: any = "";
              if (splitDateTime[1].includes("+")) {
                splitTime = splitDateTime[1].split("+", 2);
              } else {
                splitTime = splitDateTime[1].split("-", 2);
              }
              date = splitDateTime[0];
              time = splitTime[0].replace("Z", "");
              let dateTime: any = `${date} ${time}`;
              let start: any = moment(dateTime);
              let end: any = moment(
                new Date().toLocaleString("en-US", { timeZone: timezone })
              );
              hour = start.diff(end, "hours");
              minutes = start.diff(end, "minutes");
              timeDifference = localStorage.getItem("timeDifference");
              timeDifference = timeDifference / 1000;
              timeDifference = parseInt(timeDifference);
              shortbreakTimerShow = dateTime;
            }
          }
        }
        this.setState({
          isShowBreakMsg: true,
        });
      } else if (productAction === "Auction_Break") {
        //handle popup
        if (timezone !== null) {
          timezone = localStorage.getItem("timezoneName");
        }

        if (
          productBidResponse.resume_time !== null ||
          productBidResponse.resume_time === undefined
        ) {
          let previewTime = moment.utc(productBidResponse.resume_time);
          if (previewTime !== null || previewTime !== undefined) {
            let result = moment.tz(previewTime, timezone);
            let resultFormatter = result.format();
            if (resultFormatter !== "Invalid date") {
              let splitDateTime = resultFormatter.split("T", 2);
              let splitTime: any = "";
              if (splitDateTime[1].includes("+")) {
                splitTime = splitDateTime[1].split("+", 2);
              } else {
                splitTime = splitDateTime[1].split("-", 2);
              }
              date = splitDateTime[0];
              time = splitTime[0].replace("Z", "");
              let dateTime: any = `${date} ${time}`;
              let start: any = moment(dateTime);

              let end: any = moment(
                new Date().toLocaleString("en-US", { timeZone: timezone })
              );
              hour = start.diff(end, "hours");
              minutes = start.diff(end, "minutes");
              timeDifference = localStorage.getItem("timeDifference");
              timeDifference = timeDifference / 1000;
              timeDifference = parseInt(timeDifference);

              var timeDiff = start - end;
              popUpseconds = Math.round(timeDiff);
              shortbreakTimerShow = dateTime;
              {
                timeDifference[0] === "-"
                  ? (popUpseconds = popUpseconds - timeDifference)
                  : (popUpseconds = popUpseconds + timeDifference);
              }
            }
          }
        }

        this.setState({
          isPaused: false,
          isAuctionBreak: true,
          isOpenPopup: true,
          isShowBreakMsg: false,
          bidAmount: productBidResponse.product.current_bid_price,
        });
        console.log(this.state.isAuctionBreak);
        localStorage.setItem("action", "break");
      } else if (productAction === "Auction_Resumed") {
        if (this.state.isOpenPopup === true) {
          preProductId = productId;
          productId = productBidResponse.product.per_id;
        }
        if (productBidResponse.product.auction_user_id === userId) {
          this.setState({
            isbuttonDisable: true,
          });
        }
        this.setState({
          isPaused: false,
          isAuctionBreak: false,
          isOpenPopup: false,
          openAboutToStart: false,
          bidAmount: productBidResponse.product.current_bid_price,
        });
        console.log(this.state.openAboutToStart);
        localStorage.setItem("action", "null");
        clearInterval(counter);
        this.startCounter(startTime);
      }
      if (
        productAction === "NEXT_PRODUCT" ||
        productAction === "CURRENT_STATE" ||
        productAction === "SOLD" ||
        productAction === "HOLD"
      ) {
        productId = productBidResponse.product.per_id;
        console.log("NEXT_PRODUCT 1 productId" + productId);
        this.setState({
          isPaused: false,
          isAuctionBreak: false,
          openAboutToStart: false,
        });
        localStorage.setItem("action", "null");
      }
      if (isReadOnlyUser) {
        this.setState({
          isbuttonDisable: true,
        });
      }
    }
  }

  onjoin() {
    this.client.send(
      "/ws/" + auctionId + "/bid",
      {
        token: this.Auth.getToken(),
        auction_id: auctionId,
        auction_user_id: userId,
      },
      JSON.stringify('{action": "JOINED"}')
    );
  }

  showProductDetails = (product: any) => {
    let userCurrencyCode = localStorage.getItem("preferredCurrency");
    let userCurrencySymbol = localStorage.getItem("preferredCurrencySymbol");

    this.setState({
      auctionDetail: product,
      rapnetPercentage: "",
      rapnetPrice: "",
      preBidAmount: "",
      note: "",
      watched: false,
      remarks: product.remarks,
      conversionPreBidAmount: "",
      conversionCaratMinPrice: "",
      exchangeRate: "",
      exchangeRateInUsd: "",
    });

    if (product !== undefined && product != null) {
      if (product.remarks !== undefined && product.remarks != null) {
        this.setState({
          remarks: product.remarks,
        });
      }
    }

    if (product.serial_no !== undefined) {
      serialNo = product.serial_no;
    }
    if (product.current_bid_price !== undefined) {
      currentBidPrice = product.current_bid_price;

      var conversionBidAmount = "";
      var exchangerate = "";
      var exchangerateonbidAmount = "";
      if (userCurrencyCode !== product.currency.code) {
        let conversion = new Intl.NumberFormat("us-en").format(
          Math.round(product.current_bid_price * product.exchange_rates[0].rate)
        );
        let exchangeval = new Intl.NumberFormat("us-en").format(
          Math.round(
            product.carat_minimum_price * product.exchange_rates[0].rate
          )
        );
        conversionBidAmount = `(${conversion} ${userCurrencySymbol})`;
        exchangerate = `(${exchangeval} ${userCurrencySymbol})`;
        exchangerateonbidAmount = `(${conversion} ${userCurrencySymbol})`;
      } else {
        if (
          product.exchange_rate !== undefined &&
          product.exchange_rate != null
        ) {
          conversionBidAmount = product.exchange_rate;
        }
      }

      this.setState({
        bidAmount: product.current_bid_price,
        exchangeRate: exchangerate,
        exchangeRateInUsd: exchangerateonbidAmount,
      });
    }

    if (product !== undefined) {
      if (product.currency.code === "JPY") {
        productPricePerCaratUSD =
          currentBidPrice * product.exchange_rates[0].rate;
        if (currentBidPrice >= 100000) {
          this.setState({ isBidIncrease: true });
        }
      } else {
        productPricePerCaratUSD = currentBidPrice;
        if (currentBidPrice >= 1000 && currentBidPrice < 30000) {
          this.setState({ isBidIncrease: true });
        } else if (currentBidPrice >= 30000 && currentBidPrice < 100000) {
          this.setState({ isBidIncrease: false });
        } else if (currentBidPrice >= 100000) {
          this.setState({ isBidIncrease: true });
        }
      }
    }

    if (product.rapnet_price === undefined || product.rapnet_price === 0) {
      this.setState({
        rapnetPercentage: "-",
        rapnetPrice: "-",
      });
    } else {
      this.setState({
        rapnetPercentage: (
          (1 - productPricePerCaratUSD / product.rapnet_price) *
          -100.0
        ).toFixed(2),
        rapnetPrice: product.rapnet_price,
      });
    }

    var auctionDetails = storeAuctionDetails;
    for (var i = 0; i < auctionDetails.length; i++) {
      if (auctionDetails[i].productId === product.per_id) {
        if (
          auctionDetails[i].watched !== undefined ||
          auctionDetails[i].watched === true
        ) {
          this.setState({
            watched: true,
          });
        }
        if (
          auctionDetails[i].preBidPrice !== undefined ||
          auctionDetails[i].preBidPrice !== ""
        ) {
          var bidAmount = new Intl.NumberFormat("us-en").format(
            auctionDetails[i].preBidPrice
          );
          if (
            bidAmount === "NaN" ||
            bidAmount === "0" ||
            bidAmount === undefined ||
            bidAmount === ""
          ) {
            this.setState({
              preBidAmount: "",
              conversionPreBidAmount: "",
            });
          } else {
            var conversionBidAmount = "";

            if (product !== undefined && product != null) {
              if (userCurrencyCode !== product.currency.code) {
                let conversion = new Intl.NumberFormat("us-en").format(
                  Math.round(
                    auctionDetails[i].preBidPrice *
                    product.exchange_rates[0].rate
                  )
                );
                conversionBidAmount = `(${userCurrencySymbol}${conversion})`;
              } else {
                conversionBidAmount = "";
              }
            } else {
              conversionBidAmount = "";
            }

            this.setState({
              preBidAmount: `${this.state.auctionCurrency}${bidAmount}`,
              conversionPreBidAmount: conversionBidAmount,
            });
          }
        }
        if (
          auctionDetails[i].note !== undefined ||
          auctionDetails[i].note !== ""
        ) {
          this.setState({
            note: auctionDetails[i].note,
          });
        }

        break;
      }
    }
  };

  startCounter = (startTime: number) => {
    var currentTime = Date.now();
    let timeDifference: any = localStorage.getItem("timeDifference");
    timeDifference = timeDifference / 1000;
    timeDifference = parseInt(timeDifference);
    seconds = Math.round((11 * 1000 - (currentTime - startTime)) / 1000);
    if (timeDifference[0] === "-") {
      seconds = seconds - timeDifference;
    } else {
      seconds = seconds + timeDifference;
    }
    counter = setInterval(() => {
      if (productAction !== "Auction_Paused") {
        seconds = seconds - 1;
        if (seconds < 0) {
          seconds = 10;
          this.setState({
            counterSeconds: seconds,
            isbuttonDisable: false,
          });
        } else if (seconds <= 10 && seconds >= 1) {
          if (
            this.state.auctionDetail.auction_user_id !== undefined ||
            this.state.auctionDetail.seller_auction_user_id !== undefined
          ) {
            if (this.state.auctionDetail.seller_auction_user_id === userId) {
              this.setState({
                isbuttonDisable: true,
                isSeller: true,
              });
            } else if (this.state.auctionDetail.auction_user_id === userId) {
              this.setState({
                isbuttonDisable: true,
              });
            } else if (isReadOnlyUser) {
              this.setState({
                isbuttonDisable: true,
              });
            } else {
              this.setState({
                isbuttonDisable: false,
              });
            }
          } else {
            this.setState({
              isbuttonDisable: false,
            });
          }
          this.setState({
            counterSeconds: seconds,
            isPlaySound: false,
          });
        } else {
          if (seconds === 0) {
            clearInterval(counter);
          }
          this.setState({
            counterSeconds: "-",
            isbuttonDisable: true,
          });
        }
      } else {
        if (seconds < 0) {
          seconds = 10;
          clearInterval(counter);
          this.setState({
            counterSeconds: seconds,
          });
        } else {
          this.setState({
            isbuttonDisable: true,
            counterSeconds: seconds,
          });
          clearInterval(counter);
        }
      }
    }, 1000);
  };

  onBidPrice = (bidPrice: number) => {
    this.setState({
      isbuttonDisable: true,
      isPlayOnBtn: true,
      openAboutToStart: false,
    });
    var uuid = uuidv4();
    let bidPayload =
      '{"action":"BID","preProductId":"' +
      preProductId +
      '","productId":"' +
      productId +
      '","serialNo":' +
      serialNo +
      ',"bidIncreasedBy":' +
      bidPrice +
      ',"bidBasePrice":' +
      this.state.bidAmount +
      "}";
    this.client.send(
      "/ws/" + auctionId + "/bid",
      {
        token: this.Auth.getToken(),
        auction_id: auctionId,
        auction_user_id: userId,
        req_msg_id: uuid,
      },
      JSON.stringify(bidPayload)
    );
  };

  onCloseModal = () => {
    this.setState({
      openSoldOutModal: false,
      openWinnerModal: false,
      openEndedModal: false,
      openPauseModal: false,
      openHoldModal: false,
      openAboutToStart: false,
      isConnectionLost: false,
    });
  };

  auctionEndedModal = () => {
    this.props.history.push("/auction");
  };

  userBlockModal = () => {
    this.Auth.logout();
    this.props.history.replace("/");
  };

  renderer = ({
    formatted: { days, hours, minutes, seconds },
    completed,
  }: any) => {
    if (completed) {
      this.setState({
        isAuctionBreak: false,
      });
      return <span>00:00:00:00</span>;
    } else {
      return (
        <span>
          {days}:{hours}:{minutes}:{seconds}
        </span>
      );
    }
  };

  certificationPopup = (code: any) => {
    if (
      this.state.auctionDetail.lab !== "AGT" &&
      this.state.auctionDetail.lab !== "agt" &&
      this.state.auctionDetail.lab !== "" &&
      this.state.auctionDetail.lab !== undefined
    ) {
      return (
        <a
          href="#/"
          style={{ cursor: "pointer", textDecoration: "underline" }}
          onClick={() =>
            openJewelleryCertificateLinkPopup(
              this.state.auctionDetail.lab,
              this.state.auctionDetail.certificate
            )
          }
        >
          {code}
        </a>
      );
    } else {
      return <>{code}</>;
    }
  };

  renderReadOnlyAndSeller = () => {
    if (isReadOnlyUser) {
      this.setState({
        openReadOnlyUserToast: true,
      });
    } else if (this.state.isSeller) {
      this.setState({
        openReadOnlyUserToast: true,
      });
    } else {
      this.setState({
        openReadOnlyUserToast: false,
      });
    }
    setTimeout(() => {
      this.setState({
        openReadOnlyUserToast: false,
      });
    }, 3000);
  };

  resumeTimeUpdateHandler = (productBidResponse: any) => {
    if (timezone !== null) {
      timezone = localStorage.getItem("timezoneName");
    }
    let shortBreakTimer = moment.utc(productBidResponse.resume_time);
    if (shortBreakTimer !== null || shortBreakTimer !== undefined) {
      let result = moment.tz(shortBreakTimer, timezone);
      let resultFormatter = result.format();
      if (resultFormatter !== "Invalid date") {
        let splitDateTime = resultFormatter.split("T", 2);
        let splitTime: any = "";
        if (splitDateTime[1].includes("+")) {
          splitTime = splitDateTime[1].split("+", 2);
        } else {
          splitTime = splitDateTime[1].split("-", 2);
        }
        date = splitDateTime[0];
        time = splitTime[0].replace("Z", "");
        let dateTime: any = `${date} ${time}`;
        let start: any = moment(dateTime);

        let end: any = moment(
          new Date().toLocaleString("en-US", { timeZone: timezone })
        );
        hour = start.diff(end, "hours");
        minutes = start.diff(end, "minutes");
        timeDifference = localStorage.getItem("timeDifference");
        timeDifference = timeDifference / 1000;
        timeDifference = parseInt(timeDifference);

        var timeDiff = start - end;
        popUpseconds = Math.round(timeDiff);
        shortbreakTimerShow = dateTime;
        {
          timeDifference[0] === "-"
            ? (popUpseconds = popUpseconds - timeDifference)
            : (popUpseconds = popUpseconds + timeDifference);
        }
      }
    }
  };

  openProductDetail = () => {
    let myString = this.state.auctionDetail.timestamp;
    // Regular expression to match the date pattern
    const datePattern = /\d{4}-\d{2}-\d{2}/;
    // Find the first occurrence of the date pattern in the string
    const match: any = myString.match(datePattern);
    // Extract the date from the match array
    myString =
      match[0] === null && match[0] === undefined
        ? this.state.auctionDetail.timestamp
        : match[0];

    let languageCode =
      localStorage.getItem("language") === "EN"
        ? "en"
        : localStorage.getItem("language") === "zh_CN"
          ? "zh_CN"
          : "jp";
    window.open(
      `/${languageCode}/my-page/diamonds/details/${auctionId}/${this.state.auctionDetail.per_id}/${myString}`,
      "detailsWindowName",
      "height=700px,width=800px,scrollbars,toolbar,status,resizable"
    );
  };

  commasButtonRender = (value: number) => {
    return new Intl.NumberFormat("us-en").format(
      Math.round(value)
    );
  }

  render = () => {
    const { t } = this.props;
    if (
      this.state &&
      this.state.auctionDetail &&
      this.state.auctionDetail.carat_minimum_price
    ) {
      this.startingAmount = new Intl.NumberFormat("us-en").format(
        this.state.auctionDetail.carat_minimum_price
      );
    }
    if (this.startingAmount === "NaN") {
      this.startingAmount = "-";
    }

    var jewelleryAuctionDetail = "";
    var jewelleryAuctionDetailWithCertificate = "";
    //Null check here
    if (
      this.state.auctionDetail &&
      this.state.auctionDetail.code !== undefined
    ) {
      jewelleryAuctionDetail = getProductDescription(
        t,
        this.state.auctionDetail,
        false
      );
      jewelleryAuctionDetailWithCertificate = getProductDescription(
        t,
        this.state.auctionDetail,
        true
      );
    }

    if (
      this.state.counterSeconds === "" ||
      this.state.counterSeconds === undefined ||
      this.state.counterSeconds === "0"
    ) {
      this.setState({
        counterSeconds: "-",
      });
    }

    return (
      <AppLayout {...this.props}>
        <StyledGrid container={true} spacing={0}>
          <StyledWrapper item={true} xs={12} md={10} lg={10} sm={10} xl={12}>
            <Grid item={true} xs={12} md={9} lg={9} xl={12}>
              {this.state.isLoading ? (
                <div className="loader">
                  <LoadingSpinner />
                </div>
              ) : (
                <div style={{ marginTop: "20px" }}>
                  {this.state.isAuctionBreak ? (
                    <StyledBreadkPopup item={true} xs={12}>
                      <StyledComponent>
                        <StyledWrapperPopUp container spacing={0}>
                          <StyledAuctionEndedWrapper
                            item
                            md={12}
                            xs={12}
                            lg={12}
                          >
                            <StyledImage src="/images/take_break.gif" />
                            <StyledTextWrapper>
                              {t("common:auction:pasuedModal:biddingCont")}
                            </StyledTextWrapper>
                            <StyledTimer>
                              <Countdown
                                date={Date.now() + popUpseconds}
                                className="countdownText"
                                renderer={this.renderer}
                                zeroPadTime={2}
                              />
                            </StyledTimer>
                          </StyledAuctionEndedWrapper>
                        </StyledWrapperPopUp>
                      </StyledComponent>
                    </StyledBreadkPopup>
                  ) : (
                    <div>
                      {this.state.isShowBreakMsg && (
                        <StyledTextWrapper>
                          <span className="firstLine">
                            {t(
                              "common:auction:pasuedModal:headerfirstLineFirst"
                            )}{" "}
                            {shortbreakTimerShow}{" "}
                            {t(
                              "common:auction:pasuedModal:headerfirstLineSecond"
                            )}
                            {productNoLbl}
                          </span>
                        </StyledTextWrapper>
                      )}

                      <StyledCardContainer item={true} xs={12}>
                        <StyledinnerGrid container={true} spacing={2}>
                          <Grid item={true} xs={12} md={6} lg={6} xl={6}>
                            <>
                              <BidingDetailsCarousel
                                medias={this.state.auctionDetail.medias}
                                parcelType={this.state.auctionDetail.parcelType}
                              />
                            </>
                            <Grid
                              item={true}
                              xs={12}
                              style={{ marginTop: "10px" }}
                            >
                              <StyledDetailTitle>
                                #
                                <span
                                  style={{
                                    cursor: "pointer",
                                    textDecoration: "underline",
                                  }}
                                  onClick={this.openProductDetail}
                                >
                                  {this.state.auctionDetail.serial_no}
                                </span>{" "}
                                /{this.props.match.params.totalProducts}{" "}
                                <CertificationPopup
                                  auctionDetail={this.state.auctionDetail}
                                />
                                <AuctionDescription
                                  truncateLength={160}
                                  data={this.state.auctionDetail}
                                />
                              </StyledDetailTitle>
                            </Grid>
                          </Grid>
                          <Grid item={true} xs={12} md={6} lg={6}>
                            <Grid item={true} xs={12}>
                              <CardPriceSection
                                remarks={this.state.remarks ?? "-"}
                                note={this.state.note ?? "-"}
                                preBidAmount={this.state.preBidAmount ?? 0}
                                conversionPreBidAmount={
                                  this.state.conversionPreBidAmount ?? 0
                                }
                                serialNo={this.state.auctionDetail.serial_no}
                                per_id={this.state.auctionDetail.per_id}
                                parcelType={this.state.auctionDetail.parcelType}
                                startingPrice={convertedPlaceHolderValue(
                                  this.state.auctionDetail.currency,
                                  this.state.auctionDetail.carat_minimum_price,
                                  this.state.auctionDetail.exchange_rates
                                )}
                                isReserved={this.state.auctionDetail.reserved}
                              />
                            </Grid>
                            <Grid
                              item={true}
                              xs={12}
                              style={{
                                display: "flex",
                                marginTop: "10px",
                                marginBottom: "10px",
                                alignItems: "center",
                              }}
                            >
                              <Grid item={true} xs={12}>
                                <div
                                  className="display secondaryLabel labelFontSize"
                                  style={{
                                    background: "lightgray",
                                    padding: "5px",
                                  }}
                                >
                                  <StyledCurrentBid
                                    className="display fontSize"
                                    changeColor={this.state.changeColor}
                                  >
                                    <>
                                      {this.state.bidAmount !== undefined ? (
                                        <div>
                                          {this.state.bidAmount === 0 ? (
                                            <div className="fontBold textWrap">
                                              -
                                            </div>
                                          ) : (
                                            <CountUp
                                              start={
                                                this.state.auctionDetail
                                                  .carat_minimum_price
                                              }
                                              end={this.state.bidAmount}
                                              duration={0.4}
                                              formattingFn={(num) =>
                                                new Intl.NumberFormat(
                                                  "us-en"
                                                ).format(num)
                                              }
                                              className="fontBold textWrap"
                                            />
                                          )}
                                        </div>
                                      ) : (
                                        <div className="fontBold textWrap">
                                          -
                                        </div>
                                      )}
                                      <span>{this.state.auctionCurrency}</span>
                                    </>
                                    <> {this.state.exchangeRate}</>
                                  </StyledCurrentBid>
                                </div>
                              </Grid>
                            </Grid>
                            <Grid
                              item={true}
                              xs={12}
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <Grid xs={6}>
                                <StyledPaddleBox>
                                  <StyledTypo variant="h6" fontWeight={600}>
                                    {this.state.paddleNumbertobeShow}
                                  </StyledTypo>
                                </StyledPaddleBox>
                              </Grid>
                              <Grid
                                xs={6}
                                style={{
                                  display: "flex",
                                  justifyContent: "start",
                                }}
                              >
                                {this.state.counterSeconds !== "-" ? (
                                  <CircularStatic
                                    progress={parseInt(
                                      this.state.counterSeconds
                                    )}
                                  />
                                ) : (
                                  <CircularStatic progress={0} />
                                )}
                              </Grid>
                            </Grid>
                            <Grid
                              item={true}
                              xs={12}
                              style={{ marginTop: "10px" }}
                            >
                              <Grid container={true} spacing={0}>
                                {this.state.isParcelUIShow === true ? (
                                  <StyledContainer>
                                    {this.state.isbuttonDisable === false ? (
                                      <Grid
                                        container={true}
                                        spacing={0}
                                        className="spaceBetween"
                                      >
                                        {this.state.isBidIncrease === true ? (
                                          <StyledBiddingPriceContainer
                                            className="disableButton"
                                            item={true}
                                            xs={3}
                                          >
                                            <div className="fontBold">
                                              <span>+ </span>
                                              {this.state.auctionCurrency}
                                              <span>{this.commasButtonRender(this.state.button1)}</span>
                                            </div>
                                          </StyledBiddingPriceContainer>
                                        ) : (
                                          <StyledBiddingPriceContainer
                                            className="notDisablebutton"
                                            item={true}
                                            xs={3}
                                            onClick={() => {
                                              this.setState({
                                                isbuttonDisable: true,
                                              });
                                              this.onBidPrice(
                                                this.state.button1
                                              );
                                            }}
                                          >
                                            <div className="fontBold">
                                              <span>+ </span>
                                              {this.state.auctionCurrency}
                                              <span>{this.commasButtonRender(this.state.button1)}</span>
                                            </div>
                                          </StyledBiddingPriceContainer>
                                        )}
                                        <StyledBiddingPriceContainer
                                          className="notDisablebutton"
                                          item={true}
                                          xs={3}
                                          onClick={() => {
                                            this.setState({
                                              isbuttonDisable: true,
                                            });
                                            this.onBidPrice(this.state.button2);
                                          }}
                                        >
                                          <div className="fontBold">
                                            <span>+ </span>
                                            {this.state.auctionCurrency}
                                            <span>{this.commasButtonRender(this.state.button2)}</span>
                                          </div>
                                        </StyledBiddingPriceContainer>
                                        <StyledBiddingPriceContainer
                                          className="notDisablebutton"
                                          item={true}
                                          xs={3}
                                          onClick={() => {
                                            this.setState({
                                              isbuttonDisable: true,
                                            });
                                            this.onBidPrice(this.state.button3);
                                          }}
                                        >
                                          <div className="fontBold">
                                            <span>+ </span>
                                            {this.state.auctionCurrency}
                                            <span>{this.commasButtonRender(this.state.button3)}</span>
                                          </div>
                                        </StyledBiddingPriceContainer>
                                      </Grid>
                                    ) : (
                                      <Grid
                                        container={true}
                                        spacing={0}
                                        onClick={() => {
                                          this.renderReadOnlyAndSeller();
                                        }}
                                        className="spaceBetween"
                                      >
                                        <StyledDisableButton
                                          className="disableButton"
                                          item={true}
                                          xs={3}
                                        >
                                          <div className="fontBold">
                                            <span>+ </span>
                                            {this.state.auctionCurrency}
                                            <span>{this.commasButtonRender(this.state.button1)}</span>
                                          </div>
                                        </StyledDisableButton>
                                        <StyledDisableButton
                                          className="disableButton"
                                          item={true}
                                          xs={3}
                                        >
                                          <div className="fontBold">
                                            <span>+ </span>
                                            {this.state.auctionCurrency}
                                            <span>{this.commasButtonRender(this.state.button2)}</span>
                                          </div>
                                        </StyledDisableButton>
                                        <StyledDisableButton
                                          className="disableButton"
                                          item={true}
                                          xs={3}
                                        >
                                          <div className="fontBold">
                                            <span>+ </span>
                                            {this.state.auctionCurrency}
                                            <span>{this.commasButtonRender(this.state.button3)}</span>
                                          </div>
                                        </StyledDisableButton>
                                      </Grid>
                                    )}
                                  </StyledContainer>
                                ) : (
                                  <StyledContainer>
                                    {this.state.isbuttonDisable === false ? (
                                      <Grid
                                        container={true}
                                        spacing={0}
                                        className="spaceBetween"
                                      >
                                        {this.state.isBidIncrease === true ? (
                                          <StyledBiddingPriceContainer
                                            className="disableButton"
                                            item={true}
                                            xs={3}
                                          >
                                            <div className="fontBold">
                                              <span>+ </span>
                                              {this.state.auctionCurrency}
                                              <span>{this.commasButtonRender(this.state.button1)}</span>
                                            </div>
                                          </StyledBiddingPriceContainer>
                                        ) : (
                                          <StyledBiddingPriceContainer
                                            className="notDisablebutton"
                                            item={true}
                                            xs={3}
                                            onClick={() => {
                                              this.setState({
                                                isbuttonDisable: true,
                                              });
                                              this.onBidPrice(
                                                this.state.button1
                                              );
                                            }}
                                          >
                                            <div className="fontBold">
                                              <span>+ </span>
                                              {this.state.auctionCurrency}
                                              <span>{this.commasButtonRender(this.state.button1)}</span>
                                            </div>
                                          </StyledBiddingPriceContainer>
                                        )}
                                        <StyledBiddingPriceContainer
                                          className="notDisablebutton"
                                          item={true}
                                          xs={3}
                                          onClick={() => {
                                            this.setState({
                                              isbuttonDisable: true,
                                            });
                                            this.onBidPrice(this.state.button2);
                                          }}
                                        >
                                          <div className="fontBold">
                                            <span>+ </span>
                                            {this.state.auctionCurrency}
                                            <span>{this.commasButtonRender(this.state.button2)}</span>
                                          </div>
                                        </StyledBiddingPriceContainer>
                                        <StyledBiddingPriceContainer
                                          className="notDisablebutton"
                                          item={true}
                                          xs={3}
                                          onClick={() => {
                                            this.setState({
                                              isbuttonDisable: true,
                                            });
                                            this.onBidPrice(this.state.button3);
                                          }}
                                        >
                                          <div className="fontBold">
                                            <span>+ </span>
                                            {this.state.auctionCurrency}
                                            <span>{this.commasButtonRender(this.state.button3)}</span>
                                          </div>
                                        </StyledBiddingPriceContainer>
                                      </Grid>
                                    ) : (
                                      <Grid
                                        container={true}
                                        spacing={0}
                                        onClick={() => {
                                          this.renderReadOnlyAndSeller();
                                        }}
                                        className="spaceBetween"
                                      >
                                        <StyledDisableButton
                                          className="disableButton"
                                          item={true}
                                          xs={3}
                                        >
                                          <div className="fontBold">
                                            <span>+ </span>
                                            {this.state.auctionCurrency}
                                            <span>{this.commasButtonRender(this.state.button1)}</span>
                                          </div>
                                        </StyledDisableButton>
                                        <StyledDisableButton
                                          className="disableButton"
                                          item={true}
                                          xs={3}
                                        >
                                          <div className="fontBold">
                                            <span>+ </span>
                                            {this.state.auctionCurrency}
                                            <span>{this.commasButtonRender(this.state.button2)}</span>
                                          </div>
                                        </StyledDisableButton>
                                        <StyledDisableButton
                                          className="disableButton"
                                          item={true}
                                          xs={3}
                                        >
                                          <div className="fontBold">
                                            <span>+ </span>
                                            {this.state.auctionCurrency}
                                            <span>{this.commasButtonRender(this.state.button3)}</span>
                                          </div>
                                        </StyledDisableButton>
                                      </Grid>
                                    )}
                                  </StyledContainer>
                                )}
                              </Grid>
                            </Grid>
                            <Grid
                              item={true}
                              xs={12}
                              style={{
                                marginTop: "10px",
                                display: "flex",
                                justifyContent: "end",
                              }}
                            >
                              <NextProduct
                                t={t}
                                {...this.props}
                                auctionDate={startTime}
                                serialNo={serialNo}
                                productIds={this.state.auctionDetail.per_id}
                                productId={this.state.auctionDetail.per_id}
                                parcelType={
                                  this.state.auctionDetail.parcel_type
                                }
                                {...this.state.auctionDetail}
                              />
                            </Grid>
                          </Grid>
                        </StyledinnerGrid>
                        {this.state.isPaused && (
                          <>
                            <StyledMessage item xs={12}>
                              <div>
                                {t("common:auction:auctionPaused:secondLine")}
                              </div>
                              <div>
                                {t("common:auction:auctionPaused:thirdLine")}
                              </div>
                            </StyledMessage>
                          </>
                        )}
                        {this.state.isSeller && (
                          <>
                            <StyledMessage item xs={12}>
                              <div>
                                {t("common:auction:sellerMessage:firstLine")}
                              </div>
                              <div>
                                {t("common:auction:sellerMessage:secondLine")}
                              </div>
                            </StyledMessage>
                          </>
                        )}
                      </StyledCardContainer>
                    </div>
                  )}
                </div>
              )}
            </Grid>

            {this.state.isPlaySound && <Sound formBtn={false} />}
          </StyledWrapper>
        </StyledGrid>
        {this.state.openSoldOutModal && (
          <StyledModal
            open={this.state.openSoldOutModal}
            onClose={this.onCloseModal}
          >
            <ViewSoldOutModal
              t={t}
              closeModal={this.onCloseModal}
              currentBidPrice={soldBidPrice}
              auctionCurrency={this.state.auctionCurrency}
            />
          </StyledModal>
        )}
        {this.state.openEndedModal && (
          <StyledModal
            open={this.state.openEndedModal}
            onClose={this.auctionEndedModal}
          >
            <ViewEndedModal
              t={t}
              {...this.props}
              closeModal={this.auctionEndedModal}
            />
          </StyledModal>
        )}
        {this.state.openWinnerModal && (
          <StyledModal
            open={this.state.openWinnerModal}
            onClose={this.onCloseModal}
          >
            <ViewWinnerModal
              t={t}
              closeModal={this.onCloseModal}
              name={jewelleryAuctionDetail}
            />
          </StyledModal>
        )}
        {this.state.openHoldModal && (
          <StyledModal
            open={this.state.openHoldModal}
            onClose={this.onCloseModal}
          >
            <ViewHoldModal
              t={t}
              closeModal={this.onCloseModal}
              {...this.props}
            />
          </StyledModal>
        )}
        {this.state.openPauseModal && (
          <StyledModal
            open={this.state.openPauseModal}
            onClose={this.onCloseModal}
          >
            <ViewPausedModal
              t={t}
              closeModal={this.onCloseModal}
              {...this.props}
            />
          </StyledModal>
        )}
        {this.state.openCancelModal && (
          <StyledModal
            open={this.state.openCancelModal}
            onClose={this.auctionEndedModal}
          >
            <AuctionCancelled
              t={t}
              {...this.props}
              closeModal={this.auctionEndedModal}
            />
          </StyledModal>
        )}
        {this.state.openAboutToStart && (
          <StyledModal open={this.state.openAboutToStart}>
            <AboutToStart t={t} {...this.props} />
          </StyledModal>
        )}
        {this.state.isConnectionLost && (
          <StyledModal
            open={this.state.isConnectionLost}
            onClose={this.onCloseModal}
          >
            <ViewConnectLostModal
              t={t}
              {...this.props}
              closeModal={this.onCloseModal}
            />
          </StyledModal>
        )}
        {this.state.openBlockModal && (
          <StyledModal open={this.state.openBlockModal}>
            <BlockUserModal
              t={t}
              {...this.props}
              closeModal={this.userBlockModal}
            />
          </StyledModal>
        )}
        {this.state.openReadOnlyUserToast && (
          <AlertReadOnlyUser
            isReadOnlyUser={isReadOnlyUser}
            isSeller={this.state.isSeller}
          />
        )}
      </AppLayout>
    );
  };
}
